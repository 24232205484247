<template>
  <div>
    <!-- Details Org Modal -->
    <b-modal
      id="modal-details-org"
      centered
      title="Create a new Agency"
      @ok="createCurrentOrganization"
      @hidden="resetCurrentOrganization"
    >
      <div role="group">
        <label for="input-mail">Name:</label>
        <b-form-input
          id="input-name"
          v-model="currentOrg.name"
          placeholder="Enter agency's name"
          trim
        ></b-form-input>
      </div>
    </b-modal>

    <!-- Organization Management Table -->
    <b-row>
      <b-col sm="auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
      <b-col sm="auto">
        <b-input-group prepend="Per Page">
          <b-form-spinbutton
            id="sb-step"
            v-model="perPage"
            min="5"
            max="15"
            step="5"
          ></b-form-spinbutton>
        </b-input-group>
      </b-col>
      <b-col sm="8">
        <b-input-group prepend="Search">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search By Agency Name"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="clearSearch">Clear Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-overlay :show="loading">
          <b-table
            ref="orgtable"
            class="upload-list text-left"
            striped
            :items="orgListProvider"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            hover
            stacked="md"
            show-empty
            small
            sticky-header="80vh"
          >
            <template #head(Actions)="">
              <a
                href="#"
                @click="refreshOrganizationTable"
                id="btn-action-refresh"
                class="m-1 text-dark"
              >
                <i class="fas fa-sync"></i>
              </a>
            </template>
            <template #cell(FullName)="data">
              <span
                v-if="data.item.FirstName != null && data.item.LastName != null"
                >{{ data.item.FirstName }} {{ data.item.LastName }}</span
              >
              <span v-else-if="data.item.DisplayName != null">{{
                data.item.DisplayName
              }}</span>
            </template>
            <template #cell(OrganizationAppPermissions)="data">
              <b-badge
                v-for="item in data.item.OrganizationAppPermissions"
                :key="item"
                class="mr-1"
                >{{ item.split(/(?=[A-Z])/).join(" ") }}</b-badge
              >
            </template>
            <template #cell(Actions)="data">
              <!-- <a
                href="#"
                id="btn-action-edit"
                class="m-1 text-dark"
                @click="editOrganizationDetails(data.item)"
                ><i class="fas fa-edit"></i
              ></a> -->
              <a
                href="#"
                id="btn-action-delete"
                class="m-1 text-dark"
                @click="deleteOrganizationConfirm(data.item)"
                ><i class="fas fa-trash"></i
              ></a>
            </template>
          </b-table>
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="stopwatch"
                font-scale="3"
                animation="cylon"
              ></b-icon>
              <p id="cancel-label">Loading Organizations...</p>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-button class="m-1" variant="success" @click="openOrganizationDetails"
        >Create New Agency</b-button
      >
    </b-row>
  </div>
</template>
<script>
import store from "@/store/";

export default {
  name: "directory",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      filter: null,
      loading: false,
      totalRows: 0,
      cache: { 1: null },
      currentOrg: {
        name: null
      },      
      fields: [
        { key: "agencyName", label: "Agency Name"},
        { key: "created", label: "Date"},
        //{ key: "modified", label: "Last Modified" },
        { key: "Actions", label: ""}
      ]
    };
  },
  mounted() {
    this.updateRowCount();
  },
  computed: {
    possiblePermissions() {
      const profileData = store.getters.orgProfile;
      const permissions = profileData.OrganizationAppPermissions;
      var possible = [];
      for (var index in permissions) {
        var perm = permissions[index];
        possible.push(perm.split(/(?=[A-Z])/).join(" "));
      }
      return possible;
    },
  },
  methods: {
    async updateRowCount() {
      const data = await this.$api.performGET("/uuid/directory/agencies/ALL", {
        per_page: this.perPage,
      });

      if (data != null) this.totalRows = data.TotalCount;
    },
    async orgListProvider(ctx) {
      let items = [];
      this.loading = true;

      var params = {
        per_page: ctx.perPage,
        current_page: ctx.currentPage,
        search: ctx.filter,
      };

      const data = await this.$api.performGET("/uuid/directory/agencies/ALL", params);
      if (data != null) {
        items = data.Result;
        this.totalRows = data.TotalCount;
        this.cache[ctx.currentPage + 1] = {
          count: data.TotalCount,
          token: data.SkipToken,
        };
      }

      this.loading = false;
      return items;
    },

    clearSearch() {
      this.filter = null;
    },

    refreshOrganizationTable() {
      this.$refs.orgtable.refresh();
    },

    openOrganizationDetails(edit=false) {
      this.$bvModal.show("modal-details-org");
    },

    async createCurrentOrganization() {
      
      const name = this.currentOrg.name;
      const config = {
        "headers": {
          "x-functions-key": 'QruACUjUGCq1FtQvpvS8k4s1k4ESEmaZ/4FvsXbERiBya1ZWMl5D1A=='
        }
      }
      try {        
        var payload = {
          "newAgencyName": name,
          "agencyGroupID": store.getters.userProfile.Agency_Group_ID
        }
        await this.$api.performPOST('/v2/agency/add', payload);
        this.$bvModal.msgBoxOk(name + " Created", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      } catch (error) {
        this.$log.error(error);
      }
      this.refreshUserTable();
    },

    resetCurrentOrganization() {
      this.currentOrg = {
        name: null
      };
    },

    async deleteOrganizationConfirm(org) {
      var params = { agencyId: org.agencyId};
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete " + org.agencyName + "?", {
          title: "Confirm Delete",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            try {             
              this.$api.performDELETE("/v2/agency", params);
              this.$bvModal.msgBoxOk(org.agencyName + " Deleted", {
                size: "sm",
                buttonSize: "sm",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true,
              });
              this.refreshOrganizationTable();
            } catch (error) {
              this.$bvModal.msgBoxOk("Failed to delete agency", {
                size: "sm",
                buttonSize: "sm",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true,
              });
            }
          }
        });
    },
  },
};
</script>