<template>
  <div id="error">
    <b-container id="page-container" fluid>
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style>
#page-container {
  min-height: 100%;
  height: 100%;
}
</style>