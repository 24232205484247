'use strict'

import Vue from "vue";
import axios from 'axios'
import store from '@/store/index'

export let apiPluginInstance;

// Defines the functions available to the $api Vue.js variable
export class ApiPlugin
{
    constructor(vue, options)
    {
        if (!options) throw new Error('Api plugin options must be specified');
        this.pluginOptions = options;
        apiPluginInstance = this;
        Vue.prototype.$api = this;

        this.axiosInstance = axios.create({
            baseURL: this.pluginOptions.apiBaseUrl,
        });
        this.secureAxiosInstance = axios.create({
            baseURL: this.pluginOptions.secureApiBaseUrl
        });
    }

    //
    getBaseUrl()
    {
        return this.pluginOptions.apiBaseUrl;
    }

    //
    handleApiError(error)
    {
        if (error.response) console.error(error.response.data);
        throw error;
    }

    getRequestParams(params={}, secure=true)
    {
        // console.log("start getRequestParams")
        var output = params;
        if (secure) {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            if ('headers' in output) {
                output['headers']['Authorization'] = jwt;
                output['headers']['mhw-appuser'] = encodedAppUserProfile;
                output['headers']['Ocp-Apim-Subscription-Key'] = this.pluginOptions.ocpApimSubscriptionKey;
            }
            else {
                output['headers'] = {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                };
            }
        }
        // console.log("endGetRequestParams");
        return output;
    }

    getRequestParamsDELETE(params={}, secure=true)
    {
        var output = {};
        if (secure) {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            if ('headers' in output) {
                output['headers']['Authorization'] = jwt;
                output['headers']['mhw-appuser'] = encodedAppUserProfile;
                output['headers']['Ocp-Apim-Subscription-Key'] = this.pluginOptions.ocpApimSubscriptionKey;
            }
            else {
                output['headers'] = {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                };
            }          
        }
        output['data'] = params;
        return output;
    }

    
    async performGET(url, params,secure=true)
    {
        // console.log("StartPerformGet-Params")
        try {
            if (Object.keys(params).length > 0) {
                var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                url = url + "?" + queryString;
            }
            const resp = await this.axiosInstance.get(url, this.getRequestParams({}, secure));
            return resp.data;
        } catch (error) {
            this.handleApiError(error);
            return null;
        }
    }

    
    async performPOST(url, params, secure=true)
    {
        try {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                timeout: 120000,
                url: url,
                headers: {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                },
                data: params
            };
            const resp = await this.axiosInstance(config);
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }
    
    // async performGET(url, params, secure = true) {
    //     try {
    //       if (!store.getters.userProfile) {
    //         throw new Error('User profile not found');
    //       }
      
    //       const encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
    //       const { accessToken: jwt } = store.getters.msalLogin;
      
    //       if (Object.keys(params).length > 0) {
    //         const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //         url = url + '?' + queryString;
    //       }
      
    //       const config = {
    //         method: 'get',
    //         timeout: 120000,
    //         url: url,
    //         headers: this.generateHeaders(jwt, encodedAppUserProfile),
    //       };
      
    //       const resp = await this.axiosInstance(config);
    //       return resp.data;
    //     } catch (error) {
    //       this.handleApiError(error);
    //       return null;
    //     }
    //   }

    // async performPOST(url, params, secure = true) {
    //     try {
    //       if (!store.getters.userProfile) {
    //         throw new Error('User profile not found');
    //       }
      
    //       const encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
    //       const { accessToken: jwt } = store.getters.msalLogin;
      
    //       const config = {
    //         method: 'post',
    //         maxBodyLength: Infinity,
    //         timeout: 120000,
    //         url: url,
    //         headers: this.generateHeaders(jwt, encodedAppUserProfile),
    //         data: params,
    //       };
      
    //       const resp = await this.axiosInstance(config);
    //       return resp.data;
    //     } catch (error) {
    //       this.handleApiError(error);
    //       return null;
    //     }
    //   }
      
      // Helper added 4-19-2023 - Sam
      generateHeaders(jwt, encodedAppUserProfile) {
        return {
          'Authorization': jwt,
          'mhw-appuser': encodedAppUserProfile,
          'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey,
        };
      }

    //
    async performSecurePOST(url, params, secure = true)
    {
        try {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                },
                data: params
            };
            const resp = await this.secureAxiosInstance(config);
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performDELETE(url, params, secure=true)
    {
        try {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            var config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                },
                data: params,
                secure: secure
            };
            const resp = await this.axiosInstance(config);
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performPATCH(url, params, secure=true)
    {
        try {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            var config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                },
                data: params,
                secure:secure
            };
            const resp = await this.axiosInstance(config);
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performPUT(url, params, secure=true)
    {
        try {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            var config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                },
                data: params,
                secure: secure
            };
            const resp = await this.axiosInstance(config);
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performAzureFileUpload(url, file)
    {
        try {            
            // No need for auth or user token here, SAS handles that.
            var config = {
                headers: {
                    "x-ms-blob-type": "BlockBlob"
                }
            }
            // Upload form data
            const resp = await this.axiosInstance.put(url, file, config);
            return resp;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }
}

export default {
    // Called by Vue.use(ApiPlugin)
    install(vue, options) {
        new ApiPlugin(vue, options);
    }
}