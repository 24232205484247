'use strict'

import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import constants from '@/constants.js'

// Public Site
import PublicHome from '@/views/public/Home'

// Portal
import PortalLanding from '@/views/portal/Landing'
import PortalRecord from '@/views/portal/Records'
import PortalDataMapping from '@/views/portal/DataMapping'
import PortalDirectory from '@/views/portal/Directory'
import PortalMatchReview from '@/views/portal/MatchReview'
import PortalUserManagement from '@/views/portal/UserManagement'
import PortalOrgManagement from '@/views/portal/OrgManagement'
import PortalUserProfile from '@/views/portal/UserProfile'

Vue.use(Router);
var router = new Router({
  routes: [
    // Public Site
    {
      path: '/',
      name: 'Home',
      component: PublicHome,
      meta: {
        requiresAuth: false,
        layout: 'public-layout',
        requiredPermissions: null,
      }
    },

    // Portal
    {
      path: '/portal',
      name: 'Landing',
      component: PortalLanding,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'Portal',
        requiredPermissions: "BaseUser",
      }
    },
    {
      path: '/portal/profile',
      name: 'Profile',
      component: PortalUserProfile,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'User Profile',
        requiredPermissions: "BaseUser",
      }
    },
    {
      path: '/portal/data',
      name: 'Records',
      component: PortalRecord,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'Manage Records',
        requiredPermissions: "ImportData"
      }
    },
    {
      path: '/portal/data/mapping',
      name: 'Data Mapping',
      component: PortalDataMapping,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'Data Mapping',
        requiredPermissions: "MatchMerge",
      }
    },
    {
      path: '/portal/data/matches',
      name: 'Match Review',
      component: PortalMatchReview,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'Match Review',
        requiredPermissions: "BaseUser",
      }
    },
    {
      path: '/portal/directory',
      name: 'Directory',
      component: PortalDirectory,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'Directory',
        requiredPermissions: "Directory"
      }
    },
    {
      path: '/portal/users',
      name: 'User Management',
      component: PortalUserManagement,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'User Management',
        requiredPermissions: "AgencyAdministration",
      }
    },
    {
      path: '/portal/organizations',
      name: 'Agency Management',
      component: PortalOrgManagement,
      meta: {
        requiresAuth: true,
        layout: 'portal-layout',
        title: 'Agency Management',
        requiredPermissions: "AgencyAdministration",
      }
    }
  ]
});


/* eslint-disable no-unused-vars */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(Vue.prototype.$msal.getIsAuthenticated()) { // Verify MSAL session
      if (to.meta.requiredPermissions == undefined || to.meta.requiredPermissions == null) next();
      const profileData = store.getters.userProfile;
      if (profileData != undefined && profileData.UserAppPermissions != null) { // Verify permission acesss
        const permissions = profileData.UserAppPermissions;
        var found = false;
        if (Array.isArray(to.meta.requiredPermissions)) {
          for(var i=0; i<to.meta.requiredPermissions.length; i++) {
            var perm = to.meta.requiredPermissions[i];
            if (permissions.includes(perm)) {
                found = true;
                break;
            }
          }
        } else {
          found = permissions.includes(to.meta.requiredPermissions);
        }

        if (found) next(); 
        else {
          console.warn('Attempting to access a page they dont have permissions for');
          next({ path: '/portal' });
        }
      } else {
        console.warn('Attempting to access a page they dont have permissions for');
        next({ path: '/portal' });
      }
    }
    else {
      console.warn('Attempting to access a secure page');
      next({ path: '/' });
    } 
  } else {
    next(); // Proceed normally
  }
});

router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
      if (to.meta.title != undefined)
        document.title = constants.DEFAULT_TITLE + " | " + to.meta.title;
      store.commit('SET_LAYOUT', to.meta.layout);
    });
});
/* eslint-enable no-unused-vars */

export default router;