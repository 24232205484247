<template>
  <div>
    <!-- Status Dialog Box -->
    <b-modal 
        ref="status-modal" 
        centered 
        content-class="shadow"
        hide-header
        ok-only>
      <div class="d-block text-center">
        <h2 class="m-0 p-0">
          {{ statusDialog.title }} <i :class="statusDialog.icon"></i>
        </h2>
      </div>
      <div v-html="statusDialog.content" class="text-center"></div>
      <b-row v-if="statusDialogSuccess" class="mt-4">
        <b-col
          ><b-button variant="primary" block @click="hideStatusModal"
            >Download Report</b-button
          ></b-col
        >
        <b-col
          ><b-button variant="primary" block @click="hideStatusModal"
            >Export Results</b-button
          ></b-col
        >
      </b-row>
    </b-modal>

    <!-- Table Controls -->
    <b-row>
      <b-col sm="auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
      <b-col sm="auto">
        <b-input-group prepend="Per Page">
          <b-form-spinbutton
            id="sb-step"
            v-model="perPage"
            min="5"
            max="25"
            step="5"
          ></b-form-spinbutton>
        </b-input-group>
      </b-col>
      <b-col sm="8">
        <b-input-group prepend="Search">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
          <!-- <b-input-group-append>
            <b-button>Clear Search</b-button>
          </b-input-group-append> -->
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Data Table -->
    <b-row>
      <b-col sm="12">
        <b-overlay :show="loading">
          <b-table
            class="upload-list"
            striped
            :fields="fields"
            :items="recordsProvider"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            hover
            stacked="md"
            show-empty
            small
            sticky-header="80vh"
            ref="recordtable"
          >
            <template #head(Actions)="">
              <a
                href="#"
                @click="refreshRecordsTable"
                id="btn-action-refresh"
                class="m-1 text-dark"
              >
                <i class="fas fa-sync"></i>
              </a>
            </template>
            <template #cell(filename)="data">
              <!--<input class="float-left" type="checkbox" value="" />-->
              {{ data.value }}
            </template>
            <template #cell(uploadStatus)="data">
              <b-link href="#" @click="handleStatusClick(data)">
                <b-badge :variant="getStatusClassColor(data)">{{
                  getStatusDisplayName(data)
                }}</b-badge>
              </b-link>
            </template>
            <template #cell(Actions)="data">
              <a href="#" id="btn-action-report" class="m-1 text-dark" @click="handleReportClick(data)" :disabled="generatingReport.includes(data.item.id)">
                <b-spinner small variant="primary" v-if="generatingReport.includes(data.item.id)"></b-spinner>
                <i class="fas fa-chart-pie" v-if="!generatingReport.includes(data.item.id)"></i>
              </a>
              <a
                href="#"
                v-if="isAdminOrOwner(data)"
                @click="deleteRecordRow(data)"
                id="btn-action-delete"
                class="m-1 text-dark"
                ><i class="fas fa-trash"></i
              ></a>
            </template>
            <template #cell(lastStatus)="data">
              {{ getLocalTimestamp(data) }}
            </template>
          </b-table>
          <template #overlay>
            <div class="text-center" style="width:100%">
              <b-icon
                icon="stopwatch"
                font-scale="3"
                animation="cylon"
              ></b-icon>
              <p id="cancel-label">Loading Records...</p>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from "@/store/index";

export default {
  name: "records",
  data() {
    return {
      sortBy: 'customerName',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      loading: true,
      totalRows: 0,
      statusDialog: {
        title: null,
        icon: null,
        content: null
      },
      statusDialogSuccess: false,
      generatingReport: [],
      userOrganizations: []
    };
  },
  async mounted() {
    await this.getUserOrganizations();
    await this.$signalR.on("newStatus", this.refreshRecordsTable);
    
  },
  computed: {
    fields() {
      const profileData = store.getters.userProfile;
      const permissions = profileData.UserAppPermissions;

      var fields = [
        { key: "fileName", label: "Filename", sortable: true },
        { key: "uploadStatus", label: "Status", sortable: true },
        { key: "lastStatus", label: "Last Action", sortable: false},
        { key: "customerName", label: "User", sortable: true }
      ];

      if (permissions.includes("SystemAdmin")) {
        fields.push({ key: "organizationId", label: "Organization", sortable: true });
      }
      fields.push({ key: "Actions", label: "" });
      return fields;
    }
  },
  methods: {
   // disable trashcan unless the current user is the uploader or agency admin +
   isAdminOrOwner(row) {      
      return (  store.getters.userProfile.UserAppPermissions.includes("AgencyAdministration") || 
                store.getters.userProfile.UserOID == row.item.customerId);
    },
    async getUserOrganizations() {
      this.loading=true;
        const data = await this.$api.performGET('/uuid/directory/agencies/ALL', {});
        this.userOrganizations = data.Result;                
        this.$refs.recordtable.refresh();
    },
    async recordsProvider(ctx) {
      let items = [];
      this.loading = true;      
        
      var params = {
        'page': ctx.currentPage,
        'per_page': ctx.perPage,
        'search': ctx.filter,
        'orderBy': ctx.sortBy,
        'sort': ctx.sortDesc ? 'desc' : 'asc'
      };

      const profileData = store.getters.userProfile;
      const permissions = profileData.UserAppPermissions;
      var requestUrl = "/files/org/" + store.getters.userProfile.UserOrganization;
      if (permissions.includes("SystemAdmin"))
        requestUrl = "/files";
      
      const data = await this.$api.performGET(requestUrl, params);
      if (data != null) {
        items = data.Result;
        this.totalRows = data.TotalCount;
      }

      for(var i=0; i<items.length; i++) {
        var item = items[i];
        this.userOrganizations.forEach(org => {
            if (org['agencyId'] == item['organizationId'])
            {
                item['organizationId'] = org['agencyName'];
            }
        });
      }

      this.loading = false;
      return items;
    },
    refreshRecordsTable(message) {
      this.$nextTick(function() {
        try {
          this.$refs.recordtable.refresh();
        } catch(err) { this.$log.warning(err); }
      });
    },
    handleStatusClick(row) {
      const item = row.item;
      const status = row.value;

      if (status == "MAPPING_REQUIRED") {
        this.$router.push({
          path: "/portal/data/mapping",
          query: { uploadId: row.item.id },
        });
      } else if (status == "HAS_PARTIALS") {
        this.$router.push({
          path: "/portal/data/matches",
          query: { 
            batchID: row.item.datasetId,
            hasOnlyPartials: false,
            hasOnlyNew: false
          },
        });
      } else if (status == "HAS_PARTIALS_ONLY") {
        this.$router.push({
          path: "/portal/data/matches",
          query: { 
            batchID: row.item.datasetId, 
            hasOnlyPartials: true,
            hasOnlyNew: false
          },
        });
      } else if (status == "CONFIRM_NEW_RECORDS") {
        this.$router.push({
          path: "/portal/data/matches",
          query: { 
            batchID: row.item.datasetId, 
            hasOnlyPartials: false,
            hasOnlyNew: true 
          },
        });
      }
    },
    async handleReportClick(row) {
      try {
        this.generatingReport.push(row.item.id);
        const data = await this.$api.performGET("/files/statusreport/" + row.item.id, {});
        this.statusDialog = data;
        this.$refs["status-modal"].show();
      } catch (error) {
        this.$log.error(error);
        this.$bvModal.msgBoxOk("Failed to retrieve status report", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
      this.generatingReport = this.generatingReport.filter(item => item !== row.item.id);
    },
    hideStatusModal() {
      this.$refs["status-modal"].hide();
    },
    getStatusClassColor(row) {
      const item = row.item;
      const status = row.value;
      if (status == "MAPPING_REQUIRED" || status == "HAS_PARTIALS"|| status == "HAS_PARTIALS_ONLY" || status == "CONFIRM_NEW_RECORDS") {
        return "warning";
      } else if (status == "UNABLE_TO_PARSE" || status == "REJECTED") {
        return "danger";
      }
      return "success";
    },
    getStatusDisplayName(row) {
      const item = row.item;
      var status = row.value;

      if (status == 'HAS_PARTIALS_ONLY') {
        status = 'HAS_PARTIALS';
      }

      status = status.toLowerCase();
      const statusWords = status.split("_");
      for(let i=0; i<statusWords.length; i++) {
        statusWords[i] = statusWords[i][0].toUpperCase() + statusWords[i].substr(1);
      }
      return statusWords.join(" ");
    },
    getLocalTimestamp(row) {
      var dt = new Date(row.value + " UTC");
      return (
        dt.toLocaleDateString("en-US") + " " + dt.toLocaleTimeString("en-US")
      );
    },
    async deleteRecord(recordId, customerId, fileName) {
      var params = {
        id: recordId,
        customerId: customerId,
      };
      try {
        await this.$api.performDELETE("/files", params);
        this.$bvModal.msgBoxOk("File upload tracker for " +fileName + " successfully deleted", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        this.refreshRecordsTable();
      } catch (error) {
        this.$bvModal.msgBoxOk("Failed to delete record", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
    },
    deleteRecordRow(row) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete " + row.item.fileName + "?",
          {
            title: "Confirm Delete",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deleteRecord(row.item.id, row.item.customerId, row.item.fileName);
          }
        });
    },
  },
};
</script>
