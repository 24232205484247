<template>
    <b-container fluid >
      <b-overlay :show="loading">
        <b-row>
            <b-col lg="30" style="max-width: 350px;">
              <b-card class="mb-3" header="Profile" style="line-height:30%">
                  <avatar :username="loggedUserName" :size="200" :rounded="true" style="display:inline-block" ></avatar>
                  <h2 class="pt-2">{{ loggedUserName }}</h2>
                  <p><small>Agency Group: {{ agencyGroupName}} </small></p>
                  <p class="pb-2">Agency: {{ userAgencyName}} </p>
                  <p><small>Project: {{ currentTenantName }}</small></p>
                  <p></p>
              </b-card>
              <b-card class="mb-3" header="Permissions" style="max-width: 442px">
                <b-container>
                  <b-badge v-for="item in userPermissions" :key="item" class="mr-1">{{ item.split(/(?=[A-Z])/).join(' ') }}</b-badge>
                </b-container>                  
              </b-card>
              <b-card v-if="isAdmin" class="mb-3" header="Remove Project" style="margin-top:20px;" >
                  <b-container id="tenant-card-remove">
                    <b-badge pill
                      variant="danger"                    
                      v-on:click="removeTenant(tenant.tenantID, tenant.tenantName)" 
                      v-for="tenant in userTenants" 
                      :key="tenant.tenantID"                                                                 
                      class="mr-1" href="#">{{ tenant.tenantName}}
                    </b-badge>
                    <b-tooltip target="tenant-card-remove" triggers="hover">
                     Click to remove project.
                    </b-tooltip>
                  </b-container>
                </b-card>  
            </b-col>            
            <b-col lg="4" style="max-width: 350px;">                       
              <!-- <b-card class="mb-3" header="Update User Details">
                  <b-form @submit="updateProfile">
                    <b-form-group id="input-group-firstname" label="Your First Name:" label-for="input-firstname">
                      <b-form-input
                        id="input-firstname"
                        v-model="profile.FirstName"
                        placeholder="Enter First Name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-lastname" label="Your Last Name:" label-for="input-lastname">
                      <b-form-input
                        id="input-lastname"
                        v-model="profile.LastName"
                        placeholder="Enter Last Name"
                        required
                        ></b-form-input>
                      </b-form-group>
                    <b-button type="submit" class="m-2 float-right" variant="primary">Update Profile</b-button>
                  </b-form>
              </b-card> -->
              <b-card class="mb-3" header="Switch Project" style="" >
                <b-container id="tenant-card">
                  <b-badge pill
                    variant="success"                    
                    v-on:click="switchTenant(tenant.tenantID)" 
                    v-for="tenant in userTenants" 
                    :key="tenant.tenantID"                                                                 
                    class="mr-1" href="#">{{ tenant.tenantName}}
                  </b-badge>
                  <b-tooltip target="tenant-card" triggers="hover">
                    Click to switch project.
                  </b-tooltip>
                </b-container>
              </b-card> 
              <b-card class="mb-3" header="Default Project" style="" >
                <b-container id="default-project">
                  <b-form @submit="updateDefaultTenant">
                    <b-form-select                    
                      id="input-default-project"
                      v-model="newDefaultTenant"
                      list="tenant-list"                       
                      :options="availableTenants"
                    >
                    </b-form-select>                     
                    <b-button type="submit" class="m-2 float-right" variant="success">Set Default Project</b-button>
                  </b-form> 
                </b-container>
              </b-card> 
              <b-card v-if="isAdmin" header="Create New Project">
                <b-form @submit="createTenant">
                  <b-form-group id="input-group-tenant-name" label="New Project Name:" label-for="input-tenant-name">
                    <b-form-input
                      ref="newprojectname"
                      id="input-tenant-name"
                      v-model="newTenantName"
                      placeholder="Enter Project Name"
                      required
                      ></b-form-input>
                    </b-form-group>
                  <b-button type="submit" class="m-2 float-right" variant="success">Create Project</b-button>
                </b-form>
              </b-card>                
            </b-col>
            <b-col lg="4" style="max-width: 350px;"> 
            </b-col>
        </b-row>
      </b-overlay>
    </b-container>
</template>
<script>
import store from '@/store/index';
import Avatar from 'vue-avatar';

export default {
    name: 'profile',
    data() {
      return {
        profile: {
            FirstName: '',
            LastName: ''
        },
        newTenantName:'',
        loading:false,
        newDefaultTenant:''
      }
    },
    components: {
        Avatar
    }, 
    mounted() {
      this.newDefaultTenant=this.currentDefaultTenant;
    },
    computed: {
        availableTenants() {
          const FoouserTenants = store.getters.userProfile.UserTenants;
          var possible = [];
          for (var index in FoouserTenants)
          {
            var jsIsDump = FoouserTenants[index].Tenant_Name;
            possible.push(jsIsDump)
          }
          return possible;
        },
        currentDefaultTenant() {
          const userTenants = store.getters.userProfile.UserTenants;
          var zDefaultTenant="unknown";
          userTenants.forEach((userTenant) => {
            if (userTenant.Tenant_Default)            
              zDefaultTenant= userTenant.Tenant_Name;            
          });
          return zDefaultTenant;  
        },
        loggedUserName() {
          const profileData = store.getters.userProfile;
          if (profileData != undefined) return profileData.FirstName + " " + profileData.LastName;
          else return "Unknown Unknown";
        },
        userPermissions() {
          const profileData = store.getters.userProfile;
          if (profileData != undefined) return profileData.UserAppPermissions;
          else return "Unknown";            
        },
        userOrganization() {
          const profileData = store.getters.userProfile;
          if (profileData != undefined) return profileData.UserOrganization;
          else return "Unknown";                      
        },
        userAgencyName() {
          const profileData = store.getters.userProfile;
          if (profileData != undefined) return profileData.AgencyName
          else return "Unknown";
        },   
        currentTenantName() {
          const profileData = store.getters.userProfile;
          if (profileData != undefined) return profileData.CurrentTenantName
          else return "Unknown";
        },
        agencyGroupName() {
          const profileData = store.getters.userProfile;
          if (profileData != undefined) return profileData.AgencyGroupName
          else return "Unknown";
        },  
        isAdmin() {
          return store.getters.userProfile.UserAppPermissions.includes("SystemAdmin");
        } ,   
        userTenants() {
          const profileData = store.getters.userProfile;
          if (profileData != undefined) {
            var tenants =[];
            profileData.UserTenants.forEach(tenant => {
              tenants.push({
                "tenantID":tenant.Tenant_ID,
                "tenantName":tenant.Tenant_Name
              });
            });
            return tenants;
          }            
          else return "Unknown";
        }
    },
    methods: {
        async updateProfile() 
        {
            event.preventDefault();
            const profileData = store.getters.userProfile;
            const msalData = store.getters.msalLogin;

            this.profile.userOID = msalData.account.idTokenClaims.oid;
            this.profile.Mail = profileData.Mail;

            try {
                await this.$api.performPOST('graph/user/update', this.profile);
                await this.$msal.refreshProfile();
                this.$bvModal.msgBoxOk('Profile Updated!', { 
                    title: 'Success!',
                    centered: true 
                });
            } catch(error) {
                console.error(error);
                this.$bvModal.msgBoxOk('Failed to update profile', { 
                    title: 'Api Error',
                    centered: true 
                });
            }
        },
        async updateDefaultTenant()
        {          
          if (this.currentDefaultTenant==this.newDefaultTenant)
          {
            this.$bvModal.msgBoxOk(this.currentDefaultTenant + ' is currently your default project.',
              { 
                centered: true 
              }).then(z => {
                this.loading=false;  
              });  
            return;
          }
          this.loading=true;                          
          try {
            var params = {
              tenantID:this.findTenantID(this.newDefaultTenant),
              userOID:store.getters.userProfile.UserOID
            };
            await this.$api.performPOST('v2/user/setdefaulttenant', params);
                await this.$msal.refreshProfile();
                this.$bvModal.msgBoxOk('Project '+ this.newDefaultTenant +' set as Default!', { 
                  title: 'Success!',
                  centered: true 
                }).then(z => {
                  location.reload();
                  this.loading=false;  
                });
          }
          catch(error) {
            console.error(error);
              this.$bvModal.msgBoxOk('Failed to update Default Project',
              { 
                title: 'Api Error',
                centered: true 
              }).then(z => {
                location.reload();
                this.loading=false;  
              });                          
          }
        },
        findTenantID(tenantName)
        {
          const userTenants = store.getters.userProfile.UserTenants;
          var tenantID="unknown";
          userTenants.forEach((userTenant) => {
            if (userTenant.Tenant_Name == tenantName)            
              tenantID= userTenant.Tenant_ID;            
          });
          return tenantID; 
        },
        async createTenant()
        {
          this.loading = true;
          event.preventDefault();  
          try {
                var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
                var params = {
                  newTenantName: this.newTenantName,
                  mhwappuser: encodedAppUserProfile
                };
                await this.$api.performSecurePOST('orange/tenant/create', params);
                await this.$msal.refreshProfile();
                this.$bvModal.msgBoxOk('Project '+ params.newTenantName +' Created', { 
                    title: 'Success!',
                    centered: true 
                }).then(z => {
                  location.reload();
                  this.loading=false;  
                });
          } catch(error) {
            console.error(error);
              this.$bvModal.msgBoxOk('Failed to create new project',
              { 
                  title: 'Api Error',
                  centered: true 
              }).then(z => {
                  location.reload();
                  this.loading=false;  
                });                          
          }          
        },
        switchTenant: async function(e) 
        {       
          const profileData = this.$store.getters.userProfile;
          if (e != profileData.CurrentTenant)
          {
            this.loading = true;
            this.$store.commit('SET_CURRENT_TENANT', e);
            await this.$msal.refreshProfile();             
            location.reload(); //  ugly
            this.loading = false;           
          }        
        },
        removeTenant: async function(tenID, tenName)
        {          
          const profileData = this.$store.getters.userProfile;
          if (tenID != profileData.CurrentTenant)
          {
            this.$bvModal
              .msgBoxConfirm(
                "Are you sure you want to delete " + tenName + "?",
                {
                  title: "Confirm Delete",
                  size: "md",
                  buttonSize: "sm",
                  okVariant: "danger",
                  headerClass: "p-2 border-bottom-0",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                }
              ).then(async (value) => {
                if (!value)
                  {return;}
                else
                {
                  this.loading = true;
                  var params = {
                      tenantId: tenID,
                      mhwappuser: Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64')
                  };
                  await this.$api.performSecurePOST('orange/tenant/deactivate', params);
                  await this.$msal.refreshProfile();             
                  location.reload(); // ugly
                  this.loading = false;                  
                }
            });
          } 
        }
  }
}
</script>