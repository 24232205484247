'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    })
  ],
  state: {
    layout: 'public-layout',
    msalLogin: null,
    userProfile: null
  },
  mutations: {
    SET_LAYOUT (state, payload) {
      state.layout = payload
    },
    SET_MSAL_LOGIN (state, payload) {
      state.msalLogin = payload;
    },
    SET_USER_PROFILE (state, payload) {
      state.userProfile = payload;
    },
    SET_CURRENT_TENANT(state, newTenantId) {
      state.userProfile.CurrentTenant = newTenantId;
    }
    
  },
  getters: {
    layout (state) {
      return state.layout
    },
    msalLogin (state) {
      return state.msalLogin;
    },
    userProfile (state) {
      return state.userProfile;
    }
  }
})

export default store;