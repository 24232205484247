<template>
  <div>
    <b-row>
      <b-container fluid>
        <h2 class="text-left">Data Mapping</h2>
        <hr />
        <p class="text-danger text-left">{{ errorMessage }}</p>
        <!-- Schema Matching Table -->
        <b-row>
          <b-overlay :show="loading" class="w-100">
            <b-table
              bordered
              striped
              hover
              :fields="tableFields"
              :items="dataSource"
              id="tour-1"
              small
              class="w-100"
            >
              <template #cell(sourceName)="data">
                {{ data.value }}
              </template>
              <template #cell(schemaAction)="data">
                <!-- Added id, :key to support 'best practice' vue component refresh -->
                <b-form-select 
                  :key="ddkey" 
                  v-on:change="mappingRowChanged"
                  v-model="selectedHeaders[data.index]"
                  :options="rowSchemeHeaders[data.index]"
                  @input="mappingRowChanged"
                >
                </b-form-select>
              </template>
            </b-table>
            <template #overlay>
              <div class="text-center">
                <b-icon
                  icon="stopwatch"
                  font-scale="3"
                  animation="cylon"
                ></b-icon>
                <p id="cancel-label">Loading Data...</p>
              </div>
            </template>
          </b-overlay>
          <p class="float-left" style="margin-left:10px;">Required fields are marked with a *</p>
        </b-row>

        <!-- Management buttons -->
        <hr />
        <b-row class="m-2">
          <div class="w-100">
            

            <b-button
              v-b-tooltip.hover title="Skip processing partial matches when file processing completes. These records will be clearly marked in the final report."
              variant="warning"
              class="float-right ml-1"
              @click="submitConfirmSkipPartials"
              >Submit & Skip Partials</b-button
            >
            <b-button
              variant="primary"
              class="float-right ml-1"
              @click="submitConfirm"
              >Submit</b-button
            >
            <b-button
              variant="danger"
              class="float-right ml-1"
              id="tour-2"
              @click="cancelMappingRequest"
              >Cancel</b-button
            >                
          </div>
        </b-row>
        <b-row class="m-2">
          <div class="w-100">
            <!-- <b-button
              variant="outline"
              class="float-left ml-1 btn-outline-danger"
              @click="resetMappingConfirm"
              >Reset</b-button> -->

            <b-button
            style="margin-top:30px;"
              variant="outline"
              v-b-tooltip.hover title="Requires this file to have the same number of columns as the last file uploaded."
              class="float-left ml-1 btn-outline-primary"
              @click="useLastMap"
              >Use Last Mapping</b-button>
            <!-- <b-button
              variant="light"
              class="float-left ml-1 light"
              @click="debug"
              >Log</b-button> -->
          </div>
        </b-row>

      </b-container>
    </b-row>
    <DataMappingTour />
  </div>
</template>

<script>
import DataMappingTour from "@/components/DataMappingTour.vue";

// added ddkey to support 'best practice' vue component refresh
export default {
  name: "data-mapping",
  components: {
    DataMappingTour,
  },
  data() {
    return {
      tableFields: [
        { key: "schemaAction", label: "Mapping" },
        { key: "sourceName", label: "Column Name", variant: "primary" },
        { key: "sourceVal0", label: "" },
        { key: "sourceVal1", label: "" },
        { key: "sourceVal2", label: "" },
        { key: "sourceVal3", label: "" },
        { key: "sourceVal4", label: "" },
      ],
      requiredFields: [],
      dataSource: [],
      schemaHeaders: [],
      rowSchemeHeaders: {},
      selectedHeaders: {},
      mapRequest: {},
      mapContent: {},
      errorMessage: null,
      changeDetected: false,
      loading: false,
      ddkey:0,
    };
  },
  mounted() {
    this.displayDataSource();    
  },
  methods: {
    debug() {
      console.log(' ----')
      console.log(' ---- Current Object States ----');
      console.log(' ----')
      console.log('> > mapRequest');
      console.log(this.mapRequest)
      // console.log(this.mappedHeaders);
      // console.log(this.missingRequired);
      // console.log(missingFields);
      console.log('> > selectedHeaders');
      console.log(this.selectedHeaders);
      console.log('> > dataSource')
      console.log((this.dataSource));
      console.log(' > > >  User Sample Data');
      console.log(this.userDataSample);
      console.log('userSampleData.length: ' + this.userDataSample.columnIndex.length );

      // attempt validation that occurs on submit
      var mappedHeaders = [];
      var missingRequired = [];
      var missingField = false;

      for (var sIndex in this.selectedHeaders) {
        var sValue = this.selectedHeaders[sIndex];
        if (sValue != null) mappedHeaders.push(sValue);
        else missingField = true;
      }

      for (var rIndex in this.requiredFields) {
        var rValue = this.requiredFields[rIndex].value;
        var rName = this.requiredFields[rIndex].name;
        if (!mappedHeaders.includes(rValue)) missingRequired.push(rName);
      }

      // log validation results
      console.log('! ! ! validation data objects');
      console.log('> > > mappedHeaders');
      console.log(mappedHeaders);
      console.log('> > > missingRequired');
      console.log(missingRequired);
      console.log('> > > missingField');
      console.log(missingField);


      // Build object for transmission
      var patchRequest = this.mapRequest;
      var loopCount=0;
      for (var slIndex in this.selectedHeaders) 
      {
        // don't assign more fields than are available in the sample data        
        if (loopCount >= this.userDataSample.columnIndex.length ) continue;
        console.log('Loop Count: ' + loopCount++);   

        var slValue = this.selectedHeaders[slIndex];
        if (slValue == null || slValue == -1) continue;
        var sName = this.dataSource[slIndex].sourceName;

        patchRequest["mapContent"][slValue]["userColumnIndex"] = parseInt(
          slIndex
        );
        patchRequest["mapContent"][slValue]["userColumnName"] = sName;
      }
      
      // log transmission object details
      console.log('! ! ! Transmission data objects');
      console.log(patchRequest);

    },
    useLastMap() {
      var originMapContent = this.mapRequest.mapContent // array of JSON objects
      // Create new temporary array, iterate over this.mapRequest.mapContent, store data in new format
      // console.log('> > > key and columnindex from this.mapRequest.mapContent')
      // console.log(originMapContent)
      
      // iterate content from API call, build headers for use in VUE component
      for (var columnElement in originMapContent) // gets object names (ex: firstName, agencyId)
      {
        if (originMapContent[columnElement].userColumnIndex == null) continue; // user didn't match on this column, skip it.
        this.selectedHeaders[originMapContent[columnElement].userColumnIndex] = columnElement; // assign object name from JSON to selectedHeaders
      }
      // TODO
      // TODO: required & used fields may not be updating properly.
      // TODO
      this.changeDetected = true; // let the reactive system (?) know change occurred, triggers related recalcs
      this.ddkey += 1; // refresn component (the column containing the dropdowns)

    },
    submitConfirm() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to finalize this mapping?", {
          title: "Confirm Submit",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.submitUserMapping();
          }
        });
    },
    submitConfirmSkipPartials() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to finalize this mapping and skip manual resolution of any partial records?", {
          title: "Confirm Submit - Skip Partials",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.submitUserMappingSkipPartials();
          }
        });
    },

    async submitUserMapping() {
      var mappedHeaders = [];
      var missingRequired = [];
      var missingField = false;

      for (var sIndex in this.selectedHeaders) {        
        var sValue = this.selectedHeaders[sIndex];
        if (sValue != null) mappedHeaders.push(sValue);
        else missingField = true;
      }

      for (var rIndex in this.requiredFields) {
        var rValue = this.requiredFields[rIndex].value;
        var rName = this.requiredFields[rIndex].name;
        if (!mappedHeaders.includes(rValue)) missingRequired.push(rName);
      }

      if (missingRequired.length > 0) {
        this.$log.warn("Failed to submit mapping. Missing required fields");
        this.errorMessage =
          "Missing required field(s) " + missingRequired.join(", ");
        return;
      } else if (missingField) {
        this.$log.warn("Failed to submit mapping. Not all columns identified");
        this.errorMessage =
          "Please select a mapping or <Ignore this field> for every column";
        return;
      } else {
        this.errorMessage = "";
      }

      // check status of thisDataSource to determine why the reference to sourceName isn't valid downstream
      // console.log('* * * this.dataSource');
      // console.log(this.dataSource);
      // console.log('* * * this.dataSource[0]');
      // console.log(this.dataSource[0].sourceName);
      // console.log(this.dataSource.length);
      // console.log('* * * * this.selectedHeaders');
      // console.log(this.selectedHeaders);
      // console.log(this.selectedHeaders.keys)

      var patchRequest = this.mapRequest;
      var loopCount=0;
      for (var slIndex in this.selectedHeaders) {
        if (loopCount >= this.userDataSample.columnIndex.length ) continue; // memMapping
        // console.log('< SUBMIT USER MAPPING loopCount = ' + loopCount++); // memMapping
        var slValue = this.selectedHeaders[slIndex];
        if (slValue == null || slValue == -1) continue;
        var sName = this.dataSource[slIndex].sourceName;

        patchRequest["mapContent"][slValue]["userColumnIndex"] = parseInt(
          slIndex
        );
        patchRequest["mapContent"][slValue]["userColumnName"] = sName;
      }

      try {
        await this.$api.performPATCH("/maps", patchRequest);
        this.$router.push({ path: "/portal/data" });
      } catch (error) {
        this.$bvModal.msgBoxOk("Failed to submit mapping", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
    },
    //
    // dupe method to quickly implement user indication to skip manual partial resolution
    //
    async submitUserMappingSkipPartials() {
      var mappedHeaders = [];
      var missingRequired = [];
      var missingField = false;

      for (var sIndex in this.selectedHeaders) {
        var sValue = this.selectedHeaders[sIndex];
        if (sValue != null) mappedHeaders.push(sValue);
        else missingField = true;
      }

      for (var rIndex in this.requiredFields) {
        var rValue = this.requiredFields[rIndex].value;
        var rName = this.requiredFields[rIndex].name;
        if (!mappedHeaders.includes(rValue)) missingRequired.push(rName);
      }

      if (missingRequired.length > 0) {
        this.$log.warn("Failed to submit mapping. Missing required fields");
        this.errorMessage =
          "Missing required field(s) " + missingRequired.join(", ");
        return;
      } else if (missingField) {
        this.$log.warn("Failed to submit mapping. Not all columns identified");
        this.errorMessage =
          "Please select a mapping or <Ignore this field> for every column";
        return;
      } else {
        this.errorMessage = "";
      }

      var patchRequest = this.mapRequest;
      for (var slIndex in this.selectedHeaders) {
        var slValue = this.selectedHeaders[slIndex];
        if (slValue == null || slValue == -1) continue;
        var sName = this.dataSource[slIndex].sourceName;

        patchRequest["mapContent"][slValue]["userColumnIndex"] = parseInt(
          slIndex
        );
        patchRequest["mapContent"][slValue]["userColumnName"] = sName;
      }

      try {
        await this.$api.performPATCH("/maps-skipPartials", patchRequest);
        this.$router.push({ path: "/portal/data" });
      } catch (error) {
        this.$bvModal.msgBoxOk("Failed to submit mapping", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
    },
    // This does NOT reset the 'used fields' if the user manually selects some fields then hits reset. Not PROD ready.
    resetMappingConfirm() {
      if (!this.changeDetected) return;
      const that = this;
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you would like to reset the selected fields?",
          {
            title: "Confirm Reset",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            that.resetMapping();
          }
        });
    },
    resetMapping() {      
      for (var sIndex in this.selectedHeaders) { 
        this.selectedHeaders[sIndex] = null;        
      }      
      this.ddkey +=1 // cornerstone of 'best practice' vue component refresh
    },
    async mappingRowChanged(value) {
      var usedHeaders = [];
      if (!this.changeDetected) this.changeDetected = true;
      for (var sIndex in this.selectedHeaders) {
        var selected = this.selectedHeaders[sIndex];
        if (selected != null) usedHeaders.push(selected);
      }

      for (var uhIndex in usedHeaders) {
        var usedValue = usedHeaders[uhIndex];
        for (var rIndex in this.rowSchemeHeaders) {
          var row = this.rowSchemeHeaders[rIndex];
          var rowSelected = this.selectedHeaders[rIndex];
          for (var fIndex in row) {
            var fieldValue = row[fIndex].value;
            row[fIndex]["disabled"] =
              (usedHeaders.includes(fieldValue) &&
                rowSelected != usedValue &&
                fieldValue != -1) ||
              fieldValue == null;
          }
        }
      }
    },
    cancelMappingRequest() {
      if (this.changeDetected) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to cancel? All changes will be lost",
            {
              title: "Confirm Cancel",
              size: "md",
              buttonSize: "sm",
              okVariant: "danger",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            }
          )
          .then((value) => {
            if (value) this.$router.go(-1);
          });
      } else this.$router.go(-1);
    },

    async displayDataSource() {
      this.loading = true;
      var uploadId = this.$route.query.uploadId;
      const sampleRequest = await this.$api.performGET(
        "/samples/" + uploadId,
        {}
      );

      // need access to samples for Use Last Map
      this.userDataSample = sampleRequest.content;

      const sampleData = sampleRequest.content;
      const mapRequest = await this.$api.performGET("/maps/" + uploadId, {});
      this.mapRequest = mapRequest;
      this.mapContent = mapRequest.mapContent;

      var sHeaders = Object.keys(this.mapContent);
      this.schemaHeaders = [
        { value: null, text: "Identify this field...", disabled: true },
      ];

      for (var index in sHeaders) {
        this.schemaHeaders.push({
          value: sHeaders[index],
          text: this.mapContent[sHeaders[index]].uuidColName,
          required: this.mapContent[sHeaders[index]].uuidColName.endsWith("*"),
          disabled: false,
        });
        if (this.mapContent[sHeaders[index]].uuidColName.endsWith("*")) {
          this.requiredFields.push({
            value: sHeaders[index],
            name: this.mapContent[sHeaders[index]].uuidColName,
          });
        }
      }      
      for (var headerIndex in sampleData.header) {
        this.rowSchemeHeaders[headerIndex] = this.schemaHeaders;
        this.selectedHeaders[headerIndex] = null;
      }
      this.schemaHeaders.push({ value: -1, text: "<Ignore this field>" });

      const that = this;
      var sourceBuffer = {};

      for (var rowIndex in sampleData.rows) {
        var rowData = sampleData.rows[rowIndex];
        for (var columnIndex in rowData) {
          var columnData = rowData[columnIndex];
          var columnName = sampleData.header[columnIndex];
          if (!(columnName in sourceBuffer)) {
            sourceBuffer[columnName] = [];
          }
          if (columnData == "") continue;
          sourceBuffer[columnName].push(columnData);
        }
      }

      var finalRow = {};
      for (var sourceKey in sourceBuffer) {
        finalRow = {};
        finalRow["sourceName"] = sourceKey;
        var rowValues = sourceBuffer[sourceKey];
        for (var valIndex in rowValues) {
          if (valIndex > 4) break;
          finalRow["sourceVal" + valIndex] = rowValues[valIndex];
        }
        this.dataSource.push(finalRow);
      }
      this.loading = false;
    },
  },
};
</script>
<style>
.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .source-list > .col-4 {
  display: inline-block;
}
option:disabled {
  color: #c4c4c4;
  padding: 2px;
  margin: 0 0 0 0;
  background-image: none;
}
</style>