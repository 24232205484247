<template>
  <div>
    <!-- Upload Dialog -->
    <upload-file ref="uploadDialog" />

    <!-- Sidebar Menu -->
    <sidebar-menu
      :menu="menuItems"
      :collapsed="collapsed"
      @toggle-collapse="onNavToggleCollapse"
      @item-click="onNavItemClick"
    >
    </sidebar-menu>
  </div>
</template>
<script>
import { SidebarMenu } from "vue-sidebar-menu";
import * as Sentry from "@sentry/browser";
import UploadFile from "@/components/UploadFile";

export default {
  data() {
    return {
      collapsed: true,
      sidebarMenu: {
        "Data Management": [
          {
            title: "Upload File",
            icon: "fas fa-upload",
            permissions: "ImportData",
          },
          {
            href: { path: "/portal/data" },
            title: "Manage Records",
            icon: "fas fa-database",
          },
          {
            href: { path: "/portal/directory" },
            title: "View Directory",
            icon: "fas fa-folder",
          },
        ],
        "Agency Administration": [
          {
            href: { path: "/portal/organizations" },
            title: "Manage Agencies",
            icon: "fas fa-building",
          },
          {
            href: { path: "/portal/users" },
            title: "Manage Agency Users",
            icon: "fas fa-users",
          },
        ],
        Feedback: [
          {
            title: "Bug Report",
            icon: "fas fa-bug",
            permissions: "BaseUser",
          },
          {
            title: "Help",
            icon: "fas fa-question",
            permissions: "BaseUser",
          },
        ],
      },
    };
  },
  components: {
    SidebarMenu,
    UploadFile,
  },
  computed: {
    menuItems() {
      var menuOptions = [];
      for (var catKey in this.sidebarMenu) {
        var options = [];
        var entries = this.sidebarMenu[catKey];
        for (var entKey in entries) {
          var entry = entries[entKey];
          var shouldAdd = false;
          if (entry.href != undefined) {
            var route = null;
            for (var rKey in this.$router.options.routes) {
              var r = this.$router.options.routes[rKey];
              if (r.path == entry.href.path) {
                route = r;
                break;
              }
            }

            var permissions = route.meta.requiredPermissions;
            shouldAdd = this.$msal.hasUserPermissions(permissions);
          } else if (entry.permissions != undefined) {
            shouldAdd = this.$msal.hasUserPermissions(entry.permissions);
          }

          if (shouldAdd) {
            options.push(entry);
          }
        }

        if (options.length > 0) {
          menuOptions.push({
            header: true,
            title: catKey,
            hiddenOnCollapse: true,
          });
          menuOptions.push(...options);
        }
      }
      return menuOptions;
    },
  },
  methods: {
    onFeedback() {
      var event_id = Sentry.captureMessage("User Feedback");
      const userProfile = this.$msal.getUserProfile();
      Sentry.showReportDialog({
        eventId: event_id,
        title: "Report a bug",
        subtitle:
          "Please be as thorough as possible to help us recreate the problem.",
        subtitle2: "",
        name: userProfile.FirstName + " " + userProfile.LastName,
        email: userProfile.Mail,
        labelSubmit: "Submit Bug Report",
      });
    },
    onNavToggleCollapse(collapsed) {
      this.collapsed = collapsed;
      this.$emit("collapsed", collapsed);
    },
    /* eslint-disable no-unused-vars */
    onNavItemClick(event, item, node) {
      var isUpload = item.title == "Upload File";
      if (isUpload) {
        this.$refs.uploadDialog.showDialog();
      } else if (item.title == "Bug Report") {
        this.onFeedback();
      } else if (item.title == "Help") {
        Sentry.addBreadcrumb({
          category: "tour",
          message: "Attempting to start tour for route: " + this.$route.name,
          level: Sentry.Severity.Info,
        });

        switch (this.$route.name) {
          case "Landing":
            this.$tours["landing-tour"].start();
            break;
          case "Data Mapping":
            this.$tours["data-mapping-tour"].start();
            break;
          case "Match Review":
            this.$tours["matching-tour"].start();
            break;
        }
      }
    },
  },
};
</script>