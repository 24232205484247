'use strict'

import * as msal from "@azure/msal-browser";
import Vue from "vue";
import store from '@/store/index'

let msalInstance;
export let msalPluginInstance;

// Defines the functions available to the $msal Vue.js variable
export class MsalPluigin {
    constructor(Vue, options) {
        if (!options) throw new Error('MSAL plugin options must be specified');
        this.pluginOptions = options;
        msalPluginInstance = this;
        Vue.prototype.$msal = this;

        const msalConfig = {
            auth: {
                clientId: options.clientId,
                authority: options.loginAuthority,
                knownAuthorities: [options.knownAuthority]
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case msal.LogLevel.Info:
                                console.info(message);
                                return;
                            case msal.LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case msal.LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    },
                    piiLoggingEnabled: false,
                    logLevel: msal.LogLevel.Verbose
                }
            }
        }

        msalInstance = new msal.PublicClientApplication(msalConfig);
        this.isAuthenticated = this.getIsAuthenticated();
    }

    async signIn() {
        try {
            const loginRequest = {
                scopes: ["openid", "profile", "offline_access", "https://ligob2c.onmicrosoft.com/a2687a06-3039-47f6-97d2-46c65538a265/access_api"],
            };
            const loginResponse = await msalInstance.loginPopup(loginRequest);
            this.isAuthenticated = !!loginResponse.account;
            store.commit("SET_MSAL_LOGIN", loginResponse);
            await this.refreshProfile();
            if (!this.hasValidPermissions()) await this.signOut();
        } catch (err) {
            // handle error
            if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
                try {
                    const passwordResetResponse = await msalInstance.loginPopup({
                        scopes: ["openid", "profile", "offline_access", "https://ligob2c.onmicrosoft.com/a2687a06-3039-47f6-97d2-46c65538a265/access_api"],
                        authority: this.pluginOptions.passwordAuthority
                    });
                    this.isAuthenticated = !!passwordResetResponse.account;
                } catch (passwordResetError) {
                    console.error(passwordResetError);
                }
            } else {
                this.isAuthenticated = false;
            }
        }
    }

    async signOut() {
        await msalInstance.logout();
        sessionStorage.clear();
        this.isAuthenticated = false;
    }

    // api will populate active profile properties based on userProfile.CurrentTenant
    // if CurrentTenant is null or empty, default tenant will be used    
    async refreshProfile() {
        const msalData = store.getters.msalLogin;
        var userProfile = await Vue.prototype.$api.performGET('/graph/user/profile/' + msalData.account.idTokenClaims.oid, {});
        store.commit('SET_USER_PROFILE', userProfile);
    }

    getIsAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }

    getUserProfile() {
        const profileData = store.getters.userProfile;
        if (profileData == undefined) return [];
        return profileData;
    }

    getUserPermissions() {
        const profileData = store.getters.userProfile;
        if (profileData == undefined) return [];
        return profileData.UserAppPermissions;
    }

    hasValidPermissions() {
        const permissions = this.getUserPermissions();
        return permissions != null && permissions != undefined;
    }

    hasUserPermissions(requested) {
        var found = false;
        const permissions = this.getUserPermissions();
        if (Array.isArray(requested)) {
            for (var i = 0; i < requested.length; i++) {
                var perm = requested[i];
                if (permissions.includes(perm)) {
                    found = true;
                    break;
                }
            }
        } else {
            found = permissions.includes(requested);
        }
        return found;
    }

    getUserName() {
        const profileData = store.getters.userProfile;
        if (profileData != undefined) return profileData.FirstName + " " + profileData.LastName;
        else return "Unknown Unknown";
    }
}

export default {
    // Called by Vue.use(MSALPlugin)
    install(Vue, options) {
        new MsalPluigin(Vue, options);
    }
};