<template>
  <div>
    <v-tour name="matching-tour" :steps="steps"></v-tour>
  </div>
</template>

<script>
  export default {
    name: 'matching-tour',
    data () {
      return {
        steps: [
          {
            target: 'body',  
            header: {
              title: '<b style="color:green;">Green Matches</b>',
            },
            params:{placement:'bottom' },
            content: `                
                <p style="text-align:left;">
                These identities provided in your uploaded file are already present in the directory. <br/><br/>
                Your download will include the pre-existing universal unique identifier for the identity.
                </p><br/>                
            `,
          },
          {
            target: 'body',  
            header: {
              title: '<b style="color:darkcyan">Blue Matches</b>',
            },
            params:{placement:'bottom' },
            content: `            
                <p style="text-align:left;">
                These identities provided in your uploaded file did not exist in the directory. <br/><br/>
                A new universal unique identifier has been assigned to the identity.
                </p><br/>
            `,
          },
          {
            target: 'body',  
            header: {
              title: ' <b style="color:goldenrod;">Yellow Matches</b>',
            },
            params:{placement:'bottom' },
            content: `           
                <p style="text-align:left;">
                These identities provided in your uploaded file may already be present in the directory but
                we were unable to find a direct match.<br/><br/>
                Please review the possible matches and choose the existing
                identity that most closely matches the one you provided, or mark it as a new identity.
                </p><br/>
            `,
          }
        ]
      }
    }
  }
</script>
<style scoped>
.v-step { width: 620px; }
</style>