<template>
  <div id="app">
    <div v-if="devBuild" id="dev-alert">
      <i class="fas fa-exclamation-triangle"></i> You are currently accessing a
      development build of the website. Please click <a href="#">here</a> to
      return to the normal version <i class="fas fa-exclamation-triangle"></i>
    </div>
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import PublicLayout from "@/layouts/PublicLayout";
import PortalLayout from "@/layouts/PortalLayout";
import ErrorLayout from "@/layouts/ErrorLayout";

export default {
  name: "App",
  data() {
    return {};
  },
  async onIdle() {
    await this.$msal.signOut();
  },
  components: {
    "public-layout": PublicLayout,
    "portal-layout": PortalLayout,
    "error-layout": ErrorLayout
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
    devBuild() {
      if (window.webpackHotUpdate != undefined) return true;
      return process.env.VUE_DEV_BUILD == "true";
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#dev-alert {
  width: 100%;
  text-align: center;
  background-color: rgb(202, 56, 56);
  padding: 8px;
  color: #ffffff;
}

#dev-alert > a {
  color: #adadad;
}
</style>
