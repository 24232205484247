<template>
  <div>
    <!-- Invite User Modal -->
    <b-modal
      id="modal-invite-user"
      centered
      title="Invite a new user"
      @ok="submitUserInvite"
      @hidden="resetUserInvite"
    >
      <div role="group">
        <label for="input-mail">Email:</label>
        <b-form-input
          id="input-mail"
          v-model="inviteUser.Mail"
          placeholder="Enter user's email"
          trim
        ></b-form-input>

        <label for="input-first-name">First Name:</label>
        <b-form-input
          id="input-first-name"
          v-model="inviteUser.FirstName"
          placeholder="Enter user's first name"
          trim
        ></b-form-input>

        <label for="input-first-name">Last Name:</label>
        <b-form-input
          id="input-last-name"
          v-model="inviteUser.LastName"
          placeholder="Enter user's last name"
          trim
        ></b-form-input>

        <label for="input-organization">Organization:</label>
        <b-form-select
          id="input-organization"
          v-model="inviteUser.UserOrganization"
          list="organization-list"
          placeholder="Enter user's organization"
          trim
        >
          <b-form-select-option v-for="org in userOrganizations" :key="org.agencyId" :value="org.agencyId">{{ org.agencyName }}</b-form-select-option>
        </b-form-select>
        <datalist id="organization-list">
          <option v-for="org in userOrganizations" :key="org.agencyId">{{ org.agencyName }}</option>
        </datalist>

        <!-- <label for="input-permissions">Top Permission:</label>
        <b-form-select
          id="input-permissions"
          v-model="inviteUser.TopPermission"
          :options="possiblePermissions"
        ></b-form-select> -->
      </div>
    </b-modal>

    <!-- Edit User Modal -->
    <b-modal
      id="modal-edit-user"
      centered
      :title="'Edit ' + editingUser.FirstName + ' ' + editingUser.LastName"
      @ok="submitUserEdit"
      @hidden="resetUserEdit"
    >
      <div role="group">
        <label for="input-first-name">First Name:</label>
        <b-form-input
          id="input-first-name"
          v-model="editingUser.FirstName"
          placeholder="Enter user's first name"
          trim
        ></b-form-input>
        <b-form-text id="input-first-name-help">User's first name.</b-form-text>

        <label for="input-first-name">Last Name:</label>
        <b-form-input
          id="input-last-name"
          v-model="editingUser.LastName"
          placeholder="Enter user's last name"
          trim
        ></b-form-input>
        <b-form-text id="input-last-name-help">User's last name.</b-form-text>

        <b-form-select
          id="input-permissions"
          v-model="editingUser.TopPermission"
          :options="possiblePermissions"
        ></b-form-select>
        <b-form-text id="input-permissions-help"
          >User's top permission.</b-form-text
        >
      </div>
    </b-modal>

    <!-- User Management Table -->
    <b-row>
      <b-col sm="auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
      <b-col sm="auto">
        <b-input-group prepend="Per Page">
          <b-form-spinbutton
            id="sb-step"
            v-model="perPage"
            min="5"
            max="15"
            step="5"
          ></b-form-spinbutton>
        </b-input-group>
      </b-col>
      <b-col sm="8">
        <b-input-group prepend="Search">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search By First Name"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="clearSearch">Clear Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-overlay :show="loading">
          <b-table
            ref="usertable"
            class="upload-list text-left"
            striped
            :fields="fields"
            :items="userListProvider"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            hover
            stacked="md"
            show-empty
            small
            sticky-header="80vh"
          >
            <template #head(Actions)="">
              <a
                href="#"
                @click="refreshUserTable"
                id="btn-action-refresh"
                class="m-1 text-dark"
              >
                <i class="fas fa-sync"></i>
              </a>
            </template>
            <template #cell(FullName)="data">
              <span
                v-if="data.item.FirstName != null && data.item.LastName != null"
                >{{ data.item.FirstName }} {{ data.item.LastName }}</span
              >
              <span v-else-if="data.item.DisplayName != null">{{
                data.item.DisplayName
              }}</span>
            </template>
            <template #cell(UserAppPermissions)="data">
              <b-badge
                v-for="item in data.item.UserAppPermissions"
                :key="item"
                class="mr-1"
                >{{ item.split(/(?=[A-Z])/).join(" ") }}</b-badge
              >
            </template>
            <template #cell(Actions)="data">
              <a
                href="#"
                id="btn-action-edit"
                class="m-1 text-dark"
                @click="editUserDetails(data.item)"
                ><i class="fas fa-edit"></i
              ></a>
              <a
                href="#"
                id="btn-action-delete"
                class="m-1 text-dark"
                @click="deleteUserConfirm(data.item)"
                ><i class="fas fa-trash"></i
              ></a>
            </template>
          </b-table>
          <template #overlay>
            <div class="text-center">
              <b-icon 
                icon="stopwatch"
                font-scale="3"
                animation="cylon"
              ></b-icon>
              <p id="cancel-label">Loading data...</p>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row style="margin-left:1px">
      <b-button class="m-1" variant="success"  @click="inviteNewUser" 
        ><i class="fas fa-regular fa-user-plus"/>  Invite User </b-button>
    </b-row>
  </div>
</template>
<script>
import store from "@/store/";
export default {
  name: "directory",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      filter: null,
      loading: false,
      totalRows: 0,
      cache: { 1: null },
      inviteUser: {
        Mail: "",
        FirstName: "",
        LastName: "",
        UserOrganization: "",
        TopPermission: "BaseUser",
        CurrentTenant: ""
      },
      editingUser: {
        FirstName: "",
        LastName: "",
        UserOrganization: "",
        TopPermission: "BaseUser",
        CurrentTenant: "",
        UserOID:""
      },
      userOrganizations: []
    };
  },
  mounted() {
    this.updateRowCount();
    this.getUserOrganizations();
  },
  computed: {
    possiblePermissions() {
      const profileData = store.getters.userProfile;
      const permissions = profileData.UserAppPermissions;
      var possible = [];
      for (var index in permissions) {
        var perm = permissions[index];
        possible.push(perm.split(/(?=[A-Z])/).join(" "));
      }
      return possible;
    },
    fields() {
      const profileData = store.getters.userProfile;
      const permissions = profileData.UserAppPermissions;
      var fields = [
        { key: "FullName", label: "Name" },
        { key: "Mail", label: "Email" },
        { key: "UserAppPermissions", label: "User Permissions" },
      ];
      if (permissions.includes("AgencyAdministration")) {
        fields.push({ key: "AgencyName", label: "Agency" });      
        fields.push({ key: "Actions", label: "" });
      }
      return fields;
    },
  },
  methods: {
    // note that this will only show the agencies in the current tenant
    async getUserOrganizations() {
        const data = await this.$api.performGET('/uuid/directory/agencies/ALL', {});
        this.userOrganizations = data.Result;
    },
    async updateRowCount() {
      const data = await this.$api.performGET("/v2/user/read", {
        per_page: 2,
      });
      if (data != null) this.totalRows = data.TotalCount;
    },

    async userListProvider(ctx) {
      let items = [];
      this.loading = true;
      var params = {
        per_page: ctx.perPage,
        current_page: ctx.currentPage,
      };
      if (ctx.filter)
        params["filter"] = "startswith(givenname,'" + ctx.filter + "')";
      if (this.cache[ctx.currentPage] != null)
        params["skiptoken"] = this.cache[ctx.currentPage]["token"];
      const data = await this.$api.performGET("/v2/user/readbytenant", params);
      if (data != null) {        
        items = data.Result;
        this.cache[ctx.currentPage + 1] = {
          count: data.TotalCount,
          token: data.SkipToken,
        };
      }
      for(var i=0; i<items.length; i++) {
        var item = items[i];
        this.userOrganizations.forEach(org => {
            if (org['agencyId'] == item['UserOrganization'])
            {
                item['OrganizationName'] = org['agencyName'];
            }
        });
      }
      this.loading = false;
      return items;
    },
    clearSearch() {
      this.filter = null;
    },
    refreshUserTable() {
      this.updateRowCount();
      this.$refs.usertable.refresh();
    },
    async submitUserEdit() {
      var topPerm = this.editingUser.TopPermission.replace(" ", "");
      var permissionOrder = [
        "BaseUser",
        "ImportData",
        "MatchMerge",
        "Directory",
        "AgencyAdministration",
        "SystemAdmin",
      ];
      var permissionEnd = permissionOrder.indexOf(topPerm);
      this.editingUser.UserAppPermissions = permissionOrder.slice(
        0,
        permissionEnd + 1
      );
      delete this.editingUser["TopPermission"];
      try {        
        this.editingUser.CurrentTenant = store.getters.userProfile.CurrentTenant; 
        this.editingUser.newTopPermission = this.editingUser.UserAppPermissions.slice(-1).toString();
        this.editingUser.displayName = this.editingUser.FirstName + " " + this.editingUser.LastName;
        await this.$api.performPOST("/v2/user/update", this.editingUser);
        this.$bvModal.msgBoxOk("User updated.", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        this.refreshUserTable();
      } catch (error) {
        this.$bvModal.msgBoxOk("Failed to update user.", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
    },
    resetUserEdit() {
      this.editingUser = {
        FirstName: "",
        LastName: "",
        UserOrganization: "",
        TopPermission: "Base User",
        UserOID:""
      };
    },
    inviteNewUser() {
      this.$bvModal.show("modal-invite-user");
    },
    async submitUserInvite() {
      this.inviteUser.CurrentTenant = store.getters.userProfile.CurrentTenant; // 12/22
      var topPerm = this.inviteUser.TopPermission.replace(" ", "");
      var permissionOrder = [
        "BaseUser",
        "ImportData",
        "MatchMerge",
        "Directory",
        "AgencyAdministration",
        "SystemAdmin",
      ];
      var permissionEnd = permissionOrder.indexOf(topPerm);
      this.inviteUser.UserAppPermissions = permissionOrder.slice(
        0,
        permissionEnd + 1
      );
      delete this.inviteUser["TopPermission"];
      try {
        this.loading=true;
        await this.$api.performPOST(
          "graph/admin/inviteb2cuser",
          this.inviteUser
        );
        this.$bvModal.msgBoxOk("User invited.", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        this.refreshUserTable();
      } catch (error) {
        this.$bvModal.msgBoxOk("Failed to invite user.", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        this.loading=false;
      }
    },
    resetUserInvite() {
      this.inviteUser = {
        Mail: "",
        FirstName: "",
        LastName: "",
        UserOrganization: "",
        TopPermission: "Base User",
      };
    },
    editUserDetails(user) {
      this.editingUser = user;
      this.editingUser.UserOID = user.UserOID;
      if (user.UserAppPermissions != null) {
        this.editingUser.TopPermission =
          user.UserAppPermissions[user.UserAppPermissions.length - 1];
        this.editingUser.TopPermission = this.editingUser.TopPermission.split(
          /(?=[A-Z])/
        ).join(" ");
      } else {
        this.editingUser.TopPermission = "BaseUser";
      }
      this.$bvModal.show("modal-edit-user");
    },
    async deleteUser(name, oid) {
      try {
        await this.$api.performGET("/v2/user/delete/" + oid, {}); // user will remain in B2C but be deleted from DB Tenant
        this.$bvModal.msgBoxOk(name + " Deleted", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        this.refreshUserTable();
      } catch (error) {
        this.$bvModal.msgBoxOk("Failed to delete " + name, {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
    },
    async deleteUserConfirm(user) {
      var oid = user.UserOID;
      var name = user.FirstName + " " + user.LastName;
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete " + name + "?", {
          title: "Confirm Delete",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteUser(name, oid);
          }
        });
    },
  },
};
</script>