<template>
  <div>
    <v-tour name="landing-tour" :steps="steps"></v-tour>
  </div>
</template>

<script>
  export default {
    name: 'landing-tour',
    data () {
      return {
        steps: [
          {
            target: '#c-upload',  // We're using document.querySelector() under the hood
            header: {
              title: '<b>Upload File</b>',
            },
            content: `To begin, <strong>upload your data</strong>`
          },
          {
            target: '#c-manageData',
            header: {
              title: '<b>Manage Records</b>',
            },
            content: 'Here you may view uploaded files, their processing status and retrieve data'
          },
          {
            target: '#c-directory',
            header: {
              title: '<b>View Directory</b>',
            },
            content: 'View your organization\'s uploaded files. Not visible to all users.',
            params: {
              //placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            }
          },
          {
              target:'#c-manageUsers',
              header: {
              title: '<b>Manage Users</b>',
            },
              content: 'Manage user permissions. Only admins will see this'
          }
        ]
      }
    }
  }
</script>