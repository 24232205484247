<template>
  <div>
    <b-row class="mt-3">
      <b-col sm="8">
        <p>

        </p>
      </b-col>
    </b-row>
    <b-row >
      <b-col sm="auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
      <b-col sm="auto">
        <b-input-group prepend="Per Page">
          <b-form-spinbutton
            id="sb-step"
            v-model="perPage"
            min="5"
            max="25"
            step="5"
          ></b-form-spinbutton>
        </b-input-group>
      </b-col>
      <b-col sm="8">
        <b-input-group prepend="Search">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>

        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-overlay :show="loading">
          <b-table class="upload-list" striped  
              :fields="fields" 
              :items="directoryProvider" 
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              hover
              stacked="md"
              show-empty
              small
              sticky-header="80vh">
            <template #head(name)="data">
              <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
          </b-table>
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="stopwatch"
                font-scale="3"
                animation="cylon"
              ></b-icon>
              <p id="cancel-label">Loading Records...</p>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import store from "@/store/index";

export default {
  name: 'directory',
  data() {
    return {
      fields: [
        { key: 'universal_ID', label: 'ID' },
        { key: 'first_Name', label: 'First Name' },
        { key: 'middle_Name', label: 'Middle Name' },
        { key: 'last_Name', label: 'Last Name' },
        { key: 'birthdate', label: 'DOB' },
        { key: 'age', label: 'Age' },
        { key: 'action', label: '' }
      ],
      perPage: 15,
      currentPage: 1,
      filter: "",
      loading: false,
      totalRows: 0
    }
  },
  computed: {
    rows() {
      return this.exampleData.length;
    }
  },
  methods: {
    async directoryProvider(ctx) {
      let items = [];
      this.loading = true;

      var params = {
        'page': ctx.currentPage,
        'per_page': ctx.perPage,
        'search': ctx.filter,
      };

      const data = await this.$api.performGET('/uuid/directory/' + store.getters.userProfile.UserOrganization, params);
      if (data != null) {
        items = data.Result;
        this.totalRows = data.TotalCount;
      }

      this.loading = false;

      return items;
    }
  }
}
</script>
