'use strict'

import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import * as BrowserSentry from "@sentry/browser";
import constants from '@/constants';

export let loggingPluginInstance;

// Defines the functions available to the $log Vue.js variable
export class LoggingPlugin
{
    constructor(vue, options)
    {
        if (!options) throw new Error('Logging plugin options must be specified');
        this.pluginOptions = options;

        if (loggingPluginInstance == undefined) {
            this.injectConsoleHeader();
            this.configureSentry();
        }

        loggingPluginInstance = this;
        Vue.prototype.$log = this;
    }

    injectConsoleHeader() {
        console.clear();
        console.log("%c" + constants.DEFAULT_TITLE, "font-size: 30pt");
        console.log("%cThis browser window is intended only for developers. It is recommended you do not type anything into this console for data and account security.\n", "font-size: 12pt");
    
        console.log("\n\n%cApplication Log", "color:red; font-size: 25pt");
        console.log('__________________________________________________________');
    }

    configureSentry() {
        this.info('Configuring Sentry monitoring...');
        const that = this;
        Sentry.init({
            Vue,
            dsn: this.pluginOptions.sentryDSN,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: this.pluginOptions.sentryTraceSampleRate,
            tracingOptions: {
              trackComponents: true,
            },
            beforeSend(event, hint) {
                that.handleUncaughtException(event, hint);
                return event;
            }
        });       
    }

    handleUncaughtException(event, hint) {
        if (event.exception) {
            this.error(event.exception.value);
            //BrowserSentry.showReportDialog({ eventId: event.event_id });
        }
    }

    printMessage(logLevel, logMessage, formattedArguments) {
        if (['debug', 'warn', 'error', 'fatal'].includes(logLevel)) {
            console[logLevel == 'fatal' ? 'error': logLevel](logMessage, ...formattedArguments);
        } else {
            console.log(logMessage, ...formattedArguments);
        }
    }

    info(logMessage, formattedArguments = []) {
        this.printMessage('info', logMessage, formattedArguments);
    }

    debug(logMessage, formattedArguments = []) {
        this.printMessage('debug', logMessage, formattedArguments);
    }

    warn(logMessage, formattedArguments = []) {
        this.printMessage('warn', logMessage, formattedArguments);
    }

    error(logMessage, formattedArguments = []) {
        this.printMessage('error', logMessage, formattedArguments);
    }

    fatal(logMessage, formattedArguments = []) {
        this.printMessage('fatal', logMessage, formattedArguments);
    }

    clear() {
        console.clear();
        this.injectConsoleHeader();
    }
}

export default {
    // Called by Vue.use(LoggingPlugin)
    install(vue, options) {
        new LoggingPlugin(vue, options);
    }
}