<template>
  <b-modal
    ref="upload-modal"
    centered
    hide-footer
    title="Upload Data"
    @hidden="cancelUpload"
  >
    <div class="d-block text-center">
      <b-form-file
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        label-cols-sm="2"
        label-size="lg"
        accept=".xl, .xlsx, .tsv, .csv"
        v-model="selectedFileUploads"
        :disabled="fileUploading"
      >
        <template slot="file-name" slot-scope="{ names }">
          <b-badge variant="dark">{{ names[0] }}</b-badge>
          <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
            + {{ names.length - 1 }} More files
          </b-badge>
        </template>
      </b-form-file>
      <sub
        >Drag your files into the box below or click to select files using the
        file explorer</sub
      >
      <b-row class="mt-1">
        <b-col
          ><b-button
            @click="cancelUpload"
            variant="danger"
            class="mt-3"
            block
            :disabled="fileUploading"
            >Cancel</b-button
          ></b-col
        >
        <b-col>
          <b-button
            v-if="fileUploading == false"
            @click="requestUpload"
            variant="primary"
            class="mt-3"
            block
            >Upload File</b-button
          >
          <b-button
            v-else
            @click="requestUpload"
            variant="primary"
            class="mt-3"
            block
            disabled
            ><b-spinner small></b-spinner> Uploading File</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import store from "@/store/index";

export default {
  data() {
    return {
      selectedFileUploads: [],
      fileUploading: false,
    };
  },
  methods: {
    showDialog() {
      this.$refs["upload-modal"].toggle();
    },
    cancelUpload() {
      this.selectedFileUploads = [];
      this.$refs["upload-modal"].hide();
    },
    async requestFileUpload(file) {
      if (file == undefined || file == null) return; // Ignore empty requests
      const profileData = store.getters.userProfile;
      if (profileData == undefined) {
        this.$bvModal.msgBoxOk("Failed upload file. Your session is invalid", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        return;
      }

      var requestParams = {
        datasetId: "999999999",
        fileName: file.name,
        customerId: profileData.UserOID,
        customerName: profileData.FirstName + " " + profileData.LastName,
        organizationId: profileData.UserOrganization,
        tenantId: profileData.CurrentTenant
      };
      try {
        this.fileUploading = true;
        var uploadUrl = await this.$api.performPOST(
          "/file/ingress",
          requestParams
        );
        var uploadResp = await this.$api.performAzureFileUpload(
          uploadUrl,
          file
        );

        this.fileUploading = false;
        this.$refs["upload-modal"].hide();
        this.$bvModal.msgBoxOk("Upload complete.", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        this.$router.push({ path: "/portal/data" });
      } catch (error) {
        this.$log.error(error);
        this.fileUploading = false;
        this.$refs["upload-modal"].hide();

        this.$bvModal.msgBoxOk("Failed upload file.", {
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
    },
    requestUpload() {
      this.requestFileUpload(this.selectedFileUploads); //TODO: support multi uploads
    },
  },
};
</script>