'use strict'

import Vue from "vue";
import ApiPluginInstance from '@/plugins/api-plugin'

const signalR = require("@microsoft/signalr");
export let signalRPluginInstance;

// Defines the functions available to the $api Vue.js variable
export class SignalRPlugin
{
    constructor(vue, options)
    {
        if (!options) throw new Error('SignalR plugin options must be specified');
        this.pluginOptions = options;
        signalRPluginInstance = this;
        Vue.prototype.$signalR = this;

        const api = ApiPluginInstance;
        this.requestConnection();
    }

    async requestConnection()
    {
        if (this.connection != undefined) throw new Error('Attempting to establish multiple SignalR connections.');        
        const functionKey = process.env.VUE_APP_SIGNALR_NEGOTIATE_KEY;
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(Vue.prototype.$api.getBaseUrl(), {
                headers: {
                    // 'x-functions-key': functionKey,
                    'Ocp-Apim-Subscription-Key':process.env.VUE_APP_APIM_KEY
                }
              })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        try {
            this.connection = connection;
            await this.connection.start();
        } catch (err) {
            console.log(err)
        }
    }

    on(event, callback) {
        this.connection.on(event, data => callback(data));
    }

    invoke(event, data) {
        this.connection.invoke(event, data);
    }
}

export default {
    // Called by Vue.use(SignalrPlugin)
    install(vue, options) {
        new SignalRPlugin(vue, options);
    }
}