<template>
  <div>
    <b-row>
      <b-col sm="auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="maintable"
        ></b-pagination>
      </b-col>
      <b-col sm="auto">
        <b-input-group prepend="Per Page">
          <b-form-spinbutton
            id="sb-step"
            v-model="perPage"
            min="5"
            max="25"
            step="5"
          ></b-form-spinbutton>
        </b-input-group>
      </b-col>
      <b-col sm="8">
        <b-input-group prepend="Search">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
          <!-- <b-input-group-append>
            <b-button>Clear Search</b-button>
          </b-input-group-append> -->
        </b-input-group>

      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <b-input-group size="sm" prepend="Status">
          <select
            class="form-control form-control-sm"
            @click.stop.prevent
            @change="refreshTable"
            v-model="statusFilter"
            :disabled="!mergedNoMatches || this.$route.query.hasOnlyNew == 'true'"
          >
            <option
              :value="option['id']"
              v-for="option in possibleStatuses"
              :key="option['id']"
            >
              {{ option['label'] }}
            </option>
          </select>
        </b-input-group>
      </b-col>
      <b-col sm="9" v-if="showSkipPartialsButton">
        <b-button
          v-b-tooltip.hover title="Skip processing partial matches. These records will be clearly marked in the final report."
          variant="warning"
          class="float-left ml-1"
          @click="skipPartialsAndComplete"
          >Skip Partials</b-button
        >
      </b-col>
      <b-col sm="9" v-if="!mergedNoMatches">
        <b-button class="float-right" variant="success" @click="mergeNoMatches">Merge No Match results</b-button>
      </b-col>
    </b-row>

    <hr />
    
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="4" >
            <b-table
              id="maintable"
              ref="maintable"
              :fields="fields"
              :items="matchesProvider"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              style="width:1640px;table-layout:fixed;white-space:wrap;font-weight:bold"
              hover
              stacked="md"
              show-empty
              small
              sticky-header="80vh"
            >
              <template #row-details="row" >
                <b-card>
                  <b-row>
                    <b-col sm="1">
                      <b-form-group label="">
                        <b-table
                          striped
                          hover
                          borderless
                          style="width:1500px;table-layout:fixed;white-space:wrap;font-weight:normal"
                          :items="row.item.MatchingEntry.Partials"
                          :fields="partialMatchFields"
                          select-mode="single"
                          selectable
                          @row-selected="partialSelected"
                        >                      
                          <template #cell(Match)="partialRow">
                            <b-form-group>
                              <b-form-checkbox @input="adjustedPendingSelect(row, partialRow.item)" :disabled="!isOptionSelectable(row, partialRow.item, false)" v-model="partialRow.item.Match" variant="outline-secondary" class="mr-1">
                              </b-form-checkbox>
                            </b-form-group>
                          </template>
                          <template #cell(New)="partialRow">
                            <b-form-group>
                              <b-form-checkbox @input="adjustedPendingSelect(row, partialRow.item)" :disabled="!isOptionSelectable(row, partialRow.item, true)" v-model="partialRow.item.New" variant="outline-secondary" class="mr-1">
                              </b-form-checkbox>
                            </b-form-group>
                          </template>
                        </b-table>                        
                        <div class="row">
                          <div class = "col-md-12 bg-light ">
                            <b-button  
                              variant="success" 
                              style="white-space:nowrap"                          
                              @click="submitAllMatches">Submit All
                            </b-button>
                          </div>
                      </div>       
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
            <template #overlay>
              <div class="text-center">
                <b-icon
                  icon="stopwatch"
                  font-scale="3"
                  animation="cylon"
                ></b-icon>
                <p id="cancel-label" >Loading Matches...</p>
              </div>
            </template>
        </b-col>
      </b-row>
      </b-overlay>
    <MatchingTour />
    <!-- <b-button variant="primary" @click="submitAllMatches">Submit All Matches</b-button> -->
  </div>
</template>

<script>
import MatchingTour from "../../components/MatchingTour.vue";

export default {
  name: "match-review",
  components: {
    MatchingTour,
  },
  data() {
    return {
      fields: [
        // inbound record
        { key: "MatchingEntry.Person.Name.FirstName", label: "First Name", sortable: false },
        { key: "MatchingEntry.Person.Name.MiddleName", label: "M. Name", sortable: false },
        { key: "MatchingEntry.Person.Name.LastName", label: "Last Name", sortable: false },
        { key: "MatchingEntry.Person.Birthdate", label: "DOB", sortable: false , thStyle:"width:120px"},
        { key: "MatchingEntry.Person.Gender", label: "Gender", sortable: false , thStyle:"width:70px"},
        { key: "MatchingEntry.Person.Address.StreetAddress", label: "Street Address", sortable: false },
        { key: "MatchingEntry.Person.Address.City", label: "City", sortable: false },
        { key: "MatchingEntry.Person.Address.State", label: "State", sortable: false, thStyle:"width:50px"},
        { key: "MatchingEntry.Person.Address.PostalCode", label: "Zip", sortable: false , thStyle:"width:80px" },
        { key: "actions", label: "", sortable: false },
        { key: "n", label: "", sortable: false },
      ],
      perPage: 5,
      totalRows: 0,
      currentPage: 1,
      mergedNoMatches: false,      
      loading: true,
      filter: null,
      filterOn: [],
      pendingSubmition: [],
      selectedPartial: null,
      possibleStatuses: [
        {
          id: 0,
          label: "No Match"
        },
        {
          id: 1,
          label: "Matched"
        },
        {
          id: 2,
          label: "Partial Matches"
        }],
      statusFilter: 2,
      autoExpand: false,
      partialMatchFields: [
        { key: "Name.FirstName", label: "First Name" },
        { key: "Name.MiddleName", label: "M. Name"},
        { key: "Name.LastName", label: "Last Name" },
        { key: "Birthdate", label: "DOB", thStyle:"width:120px"},
        { key: "Gender", label: "Gender", thStyle:"width:70px" },
        { key: "Addresses[0].StreetAddress", label: "Address"},
        { key: "Addresses[0].City", label: "City" },
        { key: "Addresses[0].State", label: "State", thStyle:"width:50px"},
        { key: "Addresses[0].PostalCode", label: "Zip", thStyle:"width:80px" },
        { key: "Rank", label: "Rank", thStyle:"width:60px" },
        { key: "MatchScore", label: "Score" , thStyle:"width:70px"},
        { key: "Match", label: "Match", thStyle:"width:70px"},
        { key: "New", label: "New", thStyle:"width:70px"}
      ],
    };
  },
  computed: {
    showSkipPartialsButton() {
      return (this.$route.query.hasOnlyPartials == 'true' && this.mergedNoMatches==true);
    }
  },
  filters: {
    doAnything: function (value) {
      return 'didSomething';
    }
  },
  mounted() {
    var hasOnlyPartials = this.$route.query.hasOnlyPartials == 'true';
    var hasOnlyNew = this.$route.query.hasOnlyNew == 'true';    
    if (hasOnlyPartials) this.mergedNoMatches = true;
    if (hasOnlyNew || !this.mergedNoMatches) this.statusFilter = 0;

    if (!this.mergedNoMatches && !hasOnlyNew) {
      this.$bvModal.msgBoxOk(
        "Before reviewing Partial Matches you must agree to merge the No Match results into the directory. Please review these results and click the Merge No Matches button the top right of the screen.",
        {
          title: "No-Match Results",
          okVariant: "info",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      ).then((value) => {});
    }
  },
  methods: {
    formatTheThing(value)  {
      return "123";
    },
    async mergeNoMatches() {
      var batchID = this.$route.query.batchID;
      try {
        this.loading=true;
        this.mergedNoMatches = true;
        await this.$api.performPOST('/uuid/matches/commit/' + batchID);
        this.$bvModal.msgBoxOk(
              "No-Match merge request submitted!",
              {
                okVariant: "info",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              }
        ).then((value) => {
          if (this.$route.query.hasOnlyNew == "false") this.statusFilter = 2;
          this.refreshTable();
          // after clicking big green button, redirect to view of uploaded files.
          // Not a long term solution
          this.loading=false;
          this.$router.push({ path: "/portal/data" });
        });
      } catch(error) {
          this.mergedNoMatches = false;
          this.$log.error(error);
          this.$bvModal.msgBoxOk(
            "Failed to merge no match results",
            {
              okVariant: "info",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            }
          ).then((value) => {});
        }

        this.loading=false;
    },

    async skipPartialsAndComplete() {
      // Had some issues during testing with variable name similarities. Leaving for reference.
      // console.log('batchID is: '+this.$route.query.batchID);
      // console.log('BatchID is: ' + this.$route.query.BatchID);
      var skipBatchId = this.$route.query.batchID;
      // console.log('Same value as above but stored in a local var: ' + skipBatchId);
      try {
        await this.$api.performPOST('/uuid/matches/skippartials/' + skipBatchId);
        this.$bvModal.msgBoxOk(
              "Skip partials and merge request submitted!",
              {
                okVariant: "info",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              }
        ).then((value) => {          
          // this.refreshTable();
          this.$router.push({ path: "/portal/data" });
        });
      } catch(error) {
          this.$log.error(error);
          this.$bvModal.msgBoxOk(
            "Failed to skip partials and commit.",
            {
              okVariant: "info",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            }
          ).then((value) => {});
      }
    },

    partialSelected(selected) {
      this.selectedPartial = selected[0];
    },
    async matchesProvider(ctx) {
      var batchID = this.$route.query.batchID;
      let info = null;
      this.loading = true;

      var params = {
        page: ctx.currentPage,
        per_page: ctx.perPage,
        search: ctx.filter,
      };
      if (this.statusFilter != undefined) params['matchoutcome'] = this.statusFilter;

      const data = await this.$api.performGET(
        "/uuid/matches/" + batchID,
        params
      );
      if (data != null) {
        info = data[0];
      }

      let items = info.MatchingEntries;    
      if (items == undefined || items == null) {
        this.loading = false;
        return [];
      }

      items.forEach((item) => {
        var type = item.MatchingEntry.Outcome.MatchOutcome;
        var variant = 'success';
        if (type == 'Partial Matches') variant = 'warning';
        else if (type == 'Match') variant = 'success';
        else variant = 'info';

        item['_rowVariant'] = variant;
      });
      items.forEach((item) => { item['_showDetails'] = item.MatchingEntry.Outcome.MatchOutcome == 'Partial Matches'; });

      this.totalRows = info.Batch.InboundTotal;
      if (this.statusFilter == 2) this.totalRows = info.Batch.InboundPartials;
      if (this.statusFilter == 0) this.totalRows = info.Batch.InboundNoMatches;
      this.loading = false;

      return items;
    },
    refreshTable()
    {
      this.$refs.maintable.refresh()
    },
    isOptionSelectable(row, partial, isNew)
    {
      var selectUUID = partial.UUID;
      var matchUUID = row.item.MatchingEntry.Person.RecordIdentifier;

      var selectable = true;
      for (const [partialUUID, pending] of Object.entries(this.pendingSubmition)) {
        if (pending.MatchingEntry.Person.RecordIdentifier == matchUUID)
        {
          selectable = partialUUID == selectUUID;
          if (selectable)
          {
            if (isNew)
              selectable = partial.New;
            else
              selectable = partial.Match;
          }
        }
      }

      return selectable;
    },
    adjustedPendingSelect(row, partial)
    {
      var uuid = partial.UUID;
      var pending = JSON.parse(JSON.stringify(row.item));
      pending.Partial = partial;

      var shouldRemove = !partial.New && !partial.Match;
      if (shouldRemove)
        delete this.pendingSubmition[uuid];
      else
        this.pendingSubmition[uuid] = pending;
    },
    submitAllMatches() {
      this.$bvModal.msgBoxConfirm(
        "Are you sure you want to submit these selections?",
        {
          title: "Confirm Submission",
          okVariant: "danger",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      ).then(async(value) => {
        if (value) {
          var batchID = this.$route.query.batchID;
          var records = [];

          for(const [uuid, pending] of Object.entries(this.pendingSubmition))
          {
            var partial = pending.Partial;
            var outcome = -1;
            if (partial.New)
              outcome = 0;
            else if (partial.Match)
              outcome = 1;

            if (outcome == -1)
              continue;

            var record = {
              recordID: pending.MatchingEntry.Person.RecordIdentifier,
              matchOutcome: outcome
            };

            if (outcome == 1)
              record["universalID"] = uuid;

            records.push(record); 
            
          }
          // console.log("records:")
          // records.forEach(item => console.log(item.recordID + " " +item.matchOutcome)) ;
          console.log("batchid: " + batchID)
          records.forEach(item => console.log(item));
          console.log(JSON.stringify(records))

          const data = await this.$api.performPOST("/uuid/matches/partial/settle/bulk/" + batchID, records);
          this.refreshTable();
        }
      });
    },
    AttemptToSubmit(row, newMatch=false) {
      if (this.selectedPartial == null && !newMatch) 
      {
        this.$bvModal.msgBoxConfirm(
          "Please select a partial record prior to matching",
          {
            title: "Failed to perform action",
            okVariant: "danger",
            okTitle: okTitle,
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        );
        return;
      }
      var message = "Attention: You are about to permanently submit this record to the directory. Do you want to move forward?";
      if (row.MatchScore >= 95.0 && newMatch) message = "Attention: There is a 95% match score to an existing record in the directory. Are you sure you want to create a new record?";
      if (!newMatch) message = "Attention: You are about to permanently link these records in the directory. Do you want to move forward?";

      var okTitle = "Confirm New Record";
      if (!newMatch) okTitle = "Confirm Match Selection"; 
      this.$bvModal.msgBoxConfirm(
        message,
        {
          title: "Confirm Record Submission",
          okVariant: "danger",
          okTitle: okTitle,
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      ).then(async(value) => {
        if (value) {
          var batchID = this.$route.query.batchID;
          var params = {
            recordid: row.item.MatchingEntry.Person.RecordIdentifier,
            uuid: (newMatch == false ? this.selectedPartial.UUID : null),
            matchcategory: (newMatch === true ? 0 : 1),
          };
          const data = await this.$api.performGET("/uuid/matches/partial/settle/" + batchID, params);

          this.selectedPartial = null;
          this.refreshTable();
        }
      });
    },
  },
};
</script>

<style scoped>
.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .source-list > .col-4 {
  display: inline-block;
}
</style>