<template>
  <div :class="{ 'loading': isLoadingDatabase }">
  <b-container fluid>
    <!-- Main Page Body/Markdown -->
    <div id="main">
      <vue-markdown :source="landingMarkdown" class="text-left" />
    </div>

    <!-- Page Options -->
    <div id="options">
      <h2 class="text-left">Quick Access</h2>
      <br />
      <b-row>
        <div v-for="option in landingOptions" v-bind:key="option.key">
          <b-link
            :to="option.href"
            v-bind:class="option.disabled == true ? 'disabled' : ''"
          >
            <b-card>
              <i :id="'c-' + option.key" :class="option.icon" class="lg"></i>
              <b-card-text>{{ option.title }}</b-card-text>
            </b-card>
          </b-link>
          <b-tooltip
            v-if="option.disabled == false"
            :target="'c-' + option.key"
            triggers="hover"
            placement="bottom"
          >
            {{ option.tooltip }}
          </b-tooltip>
        </div>
      </b-row>      
    </div>
<!-- <b-button @click="test">Click To Check DB</b-button> -->
    <!-- Page Tour -->
    <LandingTour />
  </b-container>
    <div v-if="isLoadingDatabase" class="modal">
      <div class="modal-content">        
        <i class="fad fa-sync fa-spin fa-5x"></i>
      <p style="padding-top:40px;font-size:x-large">Provisioning assets, please wait...</p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import LandingTour from "../../components/LandingTour.vue";
import VueMarkdown from "vue-markdown-render";
import store from "@/store/";

export default {
  components: {
    LandingTour,
    VueMarkdown,
  },
  data() {
    return {
      isLoadingDatabase: false,
      buttonOptions: [
        {
          key: "manageData",
          href: { path: "/portal/data" },
          title: "Manage Records",
          icon: "fas fa-database",
          tooltip: "View uploaded data, review matches and resolve conflicts.",
        },
        {
          key: "directory",
          href: { path: "/portal/directory" },
          title: "View Directory",
          icon: "fas fa-folder",
          tooltip: "Browse, search and download contents of MatchHub.",
        },
        {
          key: "manageUsers",
          href: { path: "/portal/users" },
          title: "Manage Users",
          icon: "fas fa-users",
          tooltip: "Invite MatchHub users and manage user permissions.",
        },
      ],
    };
  },
  computed: {
    landingMarkdown() {
      return `
Welcome to MatchHub  
============

Here you will find information about MatchHub and quick access navigation buttons for the rest of the site
`;
    },
    landingOptions() {
      var options = this.buttonOptions;
      for (var optKey in options) {
        var option = options[optKey];
        var shouldAdd = false;
        if (option.href != undefined) {
          var route = null;
          for (var rKey in this.$router.options.routes) {
            var r = this.$router.options.routes[rKey];
            if (r.path == option.href.path) {
              route = r;
              break;
            }
          }

          var permissions = route.meta.requiredPermissions;
          shouldAdd = this.$msal.hasUserPermissions(permissions);
        }

        if (shouldAdd) {
          option.disabled = false;
        } else {
          option.disabled = true;
        }
      }
      return options;
    },
  },
  methods: {
    // nothing to see here... fix it post prototype though ^
    upload() {
      this.$parent.$refs["upload-modal"].toggle();
    },
    checkDatabase() {

      const isTesting = false;
      if (isTesting) { return; }
      
      var output = {};
      var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
      var jwt = store.getters.msalLogin.accessToken;
      output['headers'] = {
        'Authorization': jwt,
        'mhw-appuser': encodedAppUserProfile,
        // 'Ocp-Apim-Subscription-Key': 'f6c892d0b5554d85bfda3bcfe69e830c',//this.pluginOptions.ocpApimSubscriptionKey
        'x-functions-key': 'HSFkvgg0-yLmc1bL5gkqMFUI4uuZyuVi_iUVON8onRyvAzFu9CXoqg=='
      };
      axios.get('https://mhv2-fn-api-dev.azurewebsites.net/api/test/db/isdbactive', output)
        .then(response => {
            if (response.data) {
              // If the database is active, unlock the UI
              this.isLoadingDatabase = false;
            } else {
              // If the database is not active, retry after a delay (1 minute in this example)
              setTimeout(this.checkDatabase, 60 * 1000); // Retry after 1 minute
            }
          })
          .catch(error => {
            // If an error occurred, display a message and retry after a delay
            console.error(error);
            setTimeout(this.checkDatabase, 60 * 1000); // Retry after 1 minute
          });
    },
  },
  created() {
    this.isLoadingDatabase = true;
    this.checkDatabase();
  }

};

</script>
<style scoped>
body {
  overflow-x: hidden !important;
}

.card {
  background-color: rgba(0, 0, 255, 0.008);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  margin-right: 20px;
}
.disabled {
  color: rgba(153, 153, 153, 0.349) !important;
}
.lg {
  font-size: 400%;
  margin-top: 10px;
}
.card-text {
  margin-top: 20px;
}
.container {
  margin-top: 30px;
  margin-left: 20px;
}
a {
  color: black;
}
a:hover {
  color: #007bff;
}

#main {
  overflow: auto;
  padding-bottom: 300px;
}

#options-container {
  width: 100%;
}

#options {
  position: relative;
  bottom: 70px;
  margin-top: -250px;
  height: 250px;
  clear: both;
  padding-top: 20px;
}


.modal {
  position: fixed;
  z-index: 1000; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 4px;
  max-width: 80%;
  text-align: center;
}
</style>