<template>
  <div
    id="portal"
    :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]"
  >
    <!-- Layout Content -->
    <div class="portal-page">
      <b-navbar toggleable="lg" class="mb-2" type="dark" variant="dark">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <img id="website-logo" alt="MatchHub" src="@/assets/icon.png" height="30px" class="mr-2"/> <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item right>
              <i class="fas fa-bell"></i>
              <b-badge class="ml-1" variant="light" v-if="notificationCount > 0 && notificationCount <= 99">{{ notificationCount }}</b-badge>
              <b-badge class="ml-1" variant="light" v-else-if="notificationCount > 99">99+</b-badge>
            </b-nav-item>
            <b-nav-item-dropdown :text="loggedUserName" right>
              <b-dropdown-item right to="/portal/profile"><i class="fas fa-address-card nav-icon"></i>Profile</b-dropdown-item>
              <b-dropdown-item right @click="requestSignout"><i class="fas fa-sign-out-alt nav-icon"></i>Logout</b-dropdown-item>
            </b-nav-item-dropdown>
            <avatar :username="loggedUserName" :size="40" :rounded="false"></avatar>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-container id="page-container" fluid>
        <router-view></router-view>
      </b-container>
    </div>

    <!-- Vertical Navigation -->
    <portal-side-menu v-on:collapsed="navbarCollapsed"/>
  </div>
</template>

<script>
import store from '@/store/index';
import Avatar from 'vue-avatar';
import PortalSideMenu from '@/components/PortalSideMenu'
import constants from '@/constants'

export default {
  data() {
    return {
      breadcrumbItems: [],
      collapsed: true,
      isOnMobile: false,
    };
  },
  components: {
    Avatar,
    PortalSideMenu,
  }, 
  computed: {
    loggedUserName() {
      const profileData = store.getters.userProfile;
      if (profileData != undefined) return profileData.FirstName + " " + profileData.LastName;
      else return "Unknown Unknown";
    },
    notificationCount() {
      return 0; //TODO:
    }
  },
  mounted() {
    this.onResize();
    this.calculateBreadcrumbs();   
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
      } else {
        this.isOnMobile = false;
      }
    },
    navbarCollapsed(state) {
      this.collapsed = state;
    },
    async requestSignout() {
      await this.$msal.signOut();
      this.$router.push({ name: 'Home' });
    },
    calculateBreadcrumbs() {
      this.breadcrumbItems = [
        {
          // text: constants.DEFAULT_TITLE,
          text: store.getters.userProfile.CurrentTenantName,
          href: "#/portal/",
        },
      ];

      var parts = this.$router.currentRoute.path.split("/");
      for (var i = 0; i < parts.length; i++) {
        var part = parts[i];
        if (part != "portal" && part != "") {
          var found = null;
          this.$router.options.routes.forEach((route) => {
            if (route.path.endsWith(part)) {
              found = route;
              return;
            }
          });
          if (found != null) {
            this.breadcrumbItems.push({
              text: found.name,
              to: { path: found.path },
            });
          }
        }
      }
    },
    /* eslint-enable no-unused-vars */
    toastOnMessagesInQueue(
      variant = null,
      title = "default",
      msg = "",
      route = null
    ) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        noAutoHide: true,
        to: route,
      });
    },
  },
  watch: {
    $route() {
      this.calculateBreadcrumbs();
    },
  },
};
</script>
<style>
#website-logo {
  float: left;
  max-width: 100%;
}
#portal {
  padding-left: 350px;
  transition: 0.3s ease;
}
#portal.collapsed {
  padding-left: 50px;
}
#portal.onmobile {
  padding-left: 50px;
}
.breadcrumb {
  background: inherit !important;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
}
.nav-icon {
  margin-right: 5px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}
.separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
}
#page-container { 
    min-height: 100%;
    height: 100%;
}
</style>