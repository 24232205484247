<template>
  <div>
    <v-tour name="data-mapping-tour" :steps="steps"></v-tour>
  </div>
</template>

<script>
  export default {
    name: 'data-mapping-tour',
    data () {
      return {
        steps: [
          {
            target: '#tour-1',  // We're using document.querySelector() under the hood
            header: {
              title: 'Data Mapping',
            },
            params:{ placement: 'left'},
            content: `<p>We need a little help identifying the data you've provided.</p>
                <p>
                Please match the column names provided in the uploaded file (shown in <span style="color:goldenrod;">gold</span>)
                to the Directory column names by using the drop-down boxes (with <span style="color:green;">green </span>text) toward the top of each column.
                </p>
            `,
          },
          {
            target:'#tour-2',
            params: {placement:'top'},
            content: `Click Submit to save your selection or Cancel to clear your selections.`
          }

        ]
      }
    }
  }
</script>